import { defineStore, BaseModel } from '../store.pinia'
import { api } from '../../feathers'

export class ProjectQuestionAnswer extends BaseModel {
  static instanceDefaults () {
    return {
      value: null,
      userId: null,
      guestId: null,
      projectQuestionId: null,
    }
  }
}

const servicePath = 'project-question-answers'
export const useProjectQuestionAnswers = defineStore({ servicePath, Model: ProjectQuestionAnswer })

api.service(servicePath).hooks({})
