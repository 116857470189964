<template>
  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md" :class="{'bg-gray-light': isEmbedded}">
    <div class="py-8 px-4 shadow sm:px-10" :class="{'px-4 sm:px-4': isEmbedded, 'bg-white': !isEmbedded}">
      <div v-if="!loading && error" class="text-red mb-4">{{ error }}</div>
      <form v-if="!loading" class="space-y-6" action="#" method="POST" @submit.prevent="submit">
        <div v-if="showSentMessage" class="flex items-center justify-between bg-gray-light p-2 rounded font-semibold">
          <p>{{ $t('Users.Login view.MessageEmailSent') }}</p>
        </div>
        <div>
          <label v-if="!isEmbedded" for="email" class="block text-sm font-medium text-gray-700">
            {{ $t('Users.Login view.Label01') }}
          </label>
          <div class="mt-1">
            <input
              @keyup="showSentMessage = false"
              v-model="email" id="email"
              name="email"
              type="email"
              autocomplete="email"
              :placeholder="$t('Users.Login view.Input01_placeholder')"
              required
              class="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div v-if="!forgot">
          <label v-if="!isEmbedded" for="password" class="block text-sm font-medium text-gray-700 capitalize">
            {{ $t('Users.Login view.Label02') }}
          </label>
          <div class="mt-1">
            <input
              v-model="password"
              id="password"
              name="password"
              type="password"
              :placeholder="$t('Users.Login view.Input02_placeholder')"
              autocomplete="current-password"
              required minlength="6"
              class="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div v-if="!forgot && !isEmbedded">
          <button type="submit" class="bg-primary text-white w-full flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium bg-gray-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 capitalize">
            {{ loading ? $t('Users.Login view.Button01_loading') : $t('Users.Login view.Button01') }}
          </button>
        </div>
        <div v-if="forgot && !isEmbedded">
          <button
            :disabled="disabledEmailLogin"
            @click.prevent="emailLogin"
            class="w-full flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 capitalize"
            :class="{ 'disabled:opacity-50': disabledEmailLogin }"
          >
            {{ $t('Users.Login view.Button02') }}
          </button>
        </div>

        <div class="flex items-center justify-between" v-if="!isEmbedded">
          <div class="text-sm">
            <router-link id="create-user-link" to="/user/create" class="font-medium text-indigo-600 hover:text-indigo-500">
              {{ $t('Users.Login view.Link01') }}
            </router-link>
          </div>

          <div class="text-sm">
            <span @click="forgot = !forgot" class="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer" data-cy="forgotPasswordLink">
              <span v-if="!forgot">{{ $t('Users.Login view.Link02') }}</span>
              <span v-else>{{ $t('Users.Login view.Link02_alt') }}</span>
            </span>
          </div>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import useAuth from '@/store/store.auth'
import useGuestAuth from '@/store/store.guestAuth'
import { useStore } from '@/store/store.pinia'
import { api } from '@/feathers'

export default {
  data: () => ({
    email: '',
    password: '',
    forgot: false,
    showSentMessage: false,
    error: null,
    loading: false,
  }),
  setup () {
    const guestAuth = useGuestAuth()
    const auth = useAuth()
    const store = useStore()
    return {
      guestAuth,
      auth,
      store,
    }
  },
  props: {
    isEmbedded: {
      type: Boolean,
      default: false,
    },
    submitted: {
      type: Boolean,
      default: false,
    },
    projectTitle: {
      type: String,
      default: null,
    },
    projectId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    disabledEmailLogin () {
      return this.email.length < 3
    },
    guest () {
      return this.guestAuth.guest
    },
  },
  watch: {
    submitted (val, prevVal) {
      if (val !== prevVal) {
        this.submit()
      }
    },
  },
  methods: {
    async submit () {
      try {
        this.loading = true
        const params = {
          strategy: 'local',
          email: this.email,
          password: this.password,
        }
        if (this.guest) {
          params.guestId = this.guest.id
          params.projectTitle = this.projectTitle
          params.projectId = this.projectId
        }
        await this.auth.authenticate(params)
        if (this.guest) {
          this.guestAuth.logout()
          this.store.resetCurrentProject()
        }
        this.loading = false
        this.$router.push('/projects')
      } catch (error) {
        if (error.message === 'Invalid login') {
          this.error = this.$t('Users.Login view.ErrorMessage01')
        } else {
          this.error = this.$t('Users.Login view.ErrorMessage02')
        }
        this.loading = false
      }
    },
    async emailLogin () {
      try {
        await api.service('emailer').find({
          query: {
            email: this.email,
            action: 'forgotPassword',
          },
        })
        this.showSentMessage = true
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style>

</style>
