<template>
  <div v-if="areas">
    <ul class="mb-12 mt-12">
      <li class="grid grid-cols-12 mb-2 font-bold">
        <h3 class="col-span-6 flex items-center">Kategori/tekst</h3>
        <h3 class="col-span-6 flex items-center">Tekst</h3>
      </li>
    </ul>
    <ul data-cy="cmsTextsLists">
      <li v-for="(areaKeys, areaName) in areas" :key="areaName + updateKey" class="p-2 mb-2 bg-white bg-opacity-80">
        <h1 class="text-xl flex items-center space-x-2 cursor-pointer" @click="expandedAreas[areaName] = !expandedAreas[areaName]" data-cy>
          <BaseIcon
            :icon="expandedAreas[areaName] ? 'ChevronDown' : 'ChevronRight'"
            :size="'md'"
            :key="`expand_${areaName}_${!expandedAreas[areaName]}`"
          />
          <span>{{ areaName }}</span>
        </h1>
        <ul v-if="expandedAreas[areaName]" class="ml-8" data-cy="cmsTextsItems">
          <li v-for="(value, key) in areaKeys" :key="key" :class="typeof value !== 'object' && 'custom-divide'">
            <div v-if="typeof value === 'object'">
              <h2 class="flex items-center my-4 space-x-2 text-lg">
                <BaseIcon
                  :icon="'DesktopComputer'"
                  :size="'md'"
                  :key="`computericon_${areaName}`"
                />
                <span>{{ key }}</span>
              </h2>
              <ul class="mb-6">
                <TranslatorRow
                  class="grid grid-cols-12 custom-divide"
                  v-for="(subValue, subKey) in value" :key="subKey+key"
                  :i18nKey="`${areaName}.${key}.${subKey}`"
                  :i18nLocale="cmsLocale"
                  :i18nRefLocale="refLanguage"
                  :name="subKey"
                  v-model="areas[areaName][key][subKey]"
                  @cancel="cancelEdit"
                  @save="saveEdit($event, areaName, key, subKey)"
                />
              </ul>
            </div>
            <div v-else>
              <TranslatorRow
                class="grid grid-cols-12"
                :i18nKey="`${areaName}.${key}`"
                :i18nLocale="cmsLocale"
                :i18nRefLocale="refLanguage"
                :name="key"
                v-model="areas[areaName][key]"
                @cancel="cancelEdit"
                @save="saveEdit($event, areaName, key)"
              />
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <BaseButton
      label="opdater texter i db til ny version (bruges af udviklere)"
      @click="updateTextsInDb"
    />
    <BaseFormModal
      v-model="showModal"
      :modalTitle="'Test'"
      :formFields="[
        { type: 'hidden', name: 'id', value: 4 },
        { type: 'textInput', name: 'titleInput', label: 'title', value: 'min titel', placeholder: 'titel' },
        { type: 'textArea', name: 'descriptionInput', label: 'beskrivelse', value: 'min titel', placeholder: 'titel' },
      ]"
      @confirm="saveForm"
    />
  </div>
</template>

<script>
import TranslatorRow from '@/components/cms/TranslatorRow.vue'
import BaseIcon from '@/components/shared/baseUi/BaseIcon.vue'
import languages from '@/i18n/languages'
import BaseFormModal from '../shared/baseUi/BaseFormModal.vue'
import BaseButton from '../shared/baseUi/BaseButton.vue'
import { useFrontendTexts } from '@/store/services/frontend-texts'
import parselang from '@/i18n/parseLangObj'

export default {
  components: {
    TranslatorRow,
    BaseIcon,
    BaseFormModal,
    BaseButton,
  },
  props: {
    locale: {
      type: String,
      default: 'no_NO',
    },
  },
  setup () {
    const frontendTextsStore = useFrontendTexts()
    return {
      frontendTextsStore,
    }
  },
  data () {
    return {
      updateKey: 0,
      refLanguage: 'no_NO',
      languages: languages,
      expandedAreas: {},
      showModal: false,
      messages: null,
    }
  },
  computed: {
    cmsLocale () {
      return 'no_NO'
    },
    areas () {
      return this.messages ? this.messages[this.cmsLocale] : null
    },
    cmsLocaleLabel () {
      const currentLocale = this.languages.find(lang => lang.value === this.cmsLocale)
      if (currentLocale) {
        return currentLocale.label
      } else {
        return ''
      }
    },
  },
  watch: {
    areas: {
      deep: true,
      handler (newValue) {
        console.log(newValue)
      },
    },
    cmsLocale (newValue, oldValue) {
      this.areas = this.messages[this.cmsLocale]
    },
  },
  async created () {
    await this.getData()
    this.updateAreas()
  },
  methods: {
    async updateTextsInDb () {
      parselang(this.messages)
      await this.frontendTextsStore.patch(null, {
        content: this.messages,
      })
      this.$i18n.setLocaleMessage(this.cmsLocale, this.messages[this.cmsLocale])
    },
    async getData () {
      const r = await this.frontendTextsStore.find({
        query: {},
      })
      this.messages = r[0].content
    },
    async updateAreas () {
      for (const key in this.areas) {
        this.expandedAreas[key] = false
      }
    },
    async cancelEdit () {
    },
    async saveEdit (val, areaName, key, subKey) {
      console.log('saving value: ', val)
      if (!subKey) this.messages[this.cmsLocale][areaName][key] = val
      else this.messages[this.cmsLocale][areaName][key][subKey] = val
      const data = {
        content: this.messages,
      }
      this.frontendTextsStore.patch(null, data)
      this.$i18n.setLocaleMessage(this.cmsLocale, this.messages[this.cmsLocale])
    },
    saveForm (val) {
      console.log(val)
    },
  },
}
</script>

<style scoped>
select {
  border: none !important;
}
</style>
