<template>
  <div
    class="p-2 cursor-pointer w-12 h-12"
    @click="toggle"
  >
    <svg
      height="100%"
      width="100%"
      class="burger-btn"
      :class="active ? 'active' : ''"
      viewBox="0 0 40 26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect class="burger-btn--1" width="40" height="4" rx="3" ry="3" :fill="color"/>
      <rect class="burger-btn--2" width="40" height="4" y="10" rx="3" ry="3" :fill="color"/>
      <rect class="burger-btn--3" width="40" height="4" y="20" rx="3" ry="3" :fill="color"/>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    darkTheme: {
      type: Boolean,
      default: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color () {
      // return this.darkTheme ? 'rgb(253,252,248)' : '#2c3e50'
      return this.darkTheme ? 'rgb(253,252,248)' : '#2a6889'
    },
  },
}
</script>

<style scoped>

.burger-btn--1,
.burger-btn--2,
.burger-btn--3 {
  transition: .2s;
  transform-origin: center center;
}

.burger-btn.active .burger-btn--1 {
  transform: translate(-7px, 7px) rotate(45deg);
}

.burger-btn.active .burger-btn--2 {
  transform: translateX(20px);
  opacity: 0;
}

.burger-btn.active .burger-btn--3 {
  transform: translate(-7px, -7px) rotate(-45deg);
}

div {
  position: relative;
}

</style>
