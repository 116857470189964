<template>
  <div class="flex justify-end items-center gap-8">
    <SlideMenu
      class="z-40"
      :active="showMainMenu"
      @clickedItem="toggleMainMenu"
    />
    <BaseButton
      id="call-to-action"
      class="z-50"
      :class="!showMainMenu ? 'hidden md:flex' : ''"
      primary
      :dark="showMainMenu"
      :size="'lg'"
      :label="headerMode === 'survey' ? 'Lukk spørreskjema' : 'Start spørreskjema'"
      @click="toggleSurvey"
    />
    <BaseIconBurgerAnim
      class="z-50"
      @click="toggleMainMenu"
      :active="showMainMenu"
      :darkTheme="showMainMenu"
      data-cy="burgerButton"
    />
  </div>
</template>

<script>
import BaseButton from '../baseUi/BaseButton.vue'
import BaseIconBurgerAnim from '../baseUi/BaseIconBurgerAnim.vue'
import SlideMenu from './SlideMenu.vue'
import { useStore } from '@/store/store.pinia'
import useAuth from '@/store/store.auth'

export default {
  name: 'MainNavigation',
  setup () {
    const store = useStore()
    const auth = useAuth()
    return {
      store,
      auth,
    }
  },
  components: { BaseIconBurgerAnim, BaseButton, SlideMenu },
  computed: {
    headerMode () {
      return this.store.headerMode
    },
  },
  data () {
    return {
      showMainMenu: false,
    }
  },
  methods: {
    toggleMainMenu () {
      this.showMainMenu = !this.showMainMenu
    },
    toggleSurvey () {
      if (this.headerMode === 'survey') {
        const currentProjectId = this.store.currentProjectId
        this.store.resetCurrentProject()
        if (this.auth.isAuthenticated) {
          this.$router.push(`/project/${currentProjectId}`)
        } else {
          if (window.confirm(this.$t('Survey.ConfirmDeleteAnswers'))) {
            this.$router.push({ name: 'home' })
          }
        }
      } else {
        this.$router.push({ name: 'survey' })
      }
      this.showMainMenu = false
    },
  },
}
</script>

<style>

</style>
