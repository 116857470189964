<template>
  <div class="w-full bg-white rounded-lg bg-opacity-80 p-6">
    <h2 class="text-xl mb-2">{{$t('Survey.Questions.What demands will you make and what kind of actions will you take in this area?')}}</h2>
    <textarea
      v-model="text"
      class="w-full bg-gray-light p-3"></textarea>
  </div>
</template>

<script>
export default {
  name: 'SurveyCategoryAnswer',
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    text: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('updateCategoryAnswer', value)
      },
    },
  },
}
</script>
