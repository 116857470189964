import { defineStore, BaseModel } from '../store.pinia'
import { api } from '../../feathers'
import { Question } from './questions'
import { CategoryTranslation } from './category-translations'

export class Category extends BaseModel {
  static instanceDefaults () {
    return {
      icon: null,
      color: null,
      isArchive: false,
      position: 0,
      frameworkId: 1,
    }
  }

  static setupInstance (data, { models }) {
    if (data.questions) {
      data.questions = data.questions.map(item => new Question(item))
    }
    if (data.defaultTranslation) {
      data.defaultTranslation = new CategoryTranslation(data.defaultTranslation)
    }
    return data
  }
}

const servicePath = 'categories'
export const useCategories = defineStore({ servicePath, Model: Category })

api.service(servicePath).hooks({})
