import { defineStore, BaseModel } from '../store.pinia'
import { api } from '../../feathers'

export class Media extends BaseModel {
  static instanceDefaults () {
    return {
      type: null,
      mimetype: null,
      location: null,
    }
  }
}

const servicePath = 'media'
export const useMedia = defineStore({ servicePath, Model: Media })

api.service(servicePath).hooks({})
