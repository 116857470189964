import { defineAuthStore } from 'feathers-pinia'
import { api as feathersClient } from '../feathers'
import { User, useUsers } from './services/users'

const useAuth = defineAuthStore({
  feathersClient,
  id: 'auth',
  state: () => ({
    userId: null,
  }),
  getters: {
    getUser () {
      return this.userId ? User.getFromStore(this.userId) : null
    },
  },
  actions: {
    handleResponse (response) {
      const UserModel = useUsers().Model
      this.userId = response.user.id || response.user._id
      UserModel.addToStore(response.user)
      return response
    },
    async logout () {
      localStorage.removeItem('user-jwt')
      this.userId = null
      this.isAuthenticated = false
      this.accessToken = null
    },
  },
})

export default useAuth
