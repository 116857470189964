<template>
  <h1 class="text-3xl pb-6">
    <img :src="`${$appConfig.apiBaseUrl}${projectCategory.category.icon}`" class="h-12 inline-block">
    {{ projectCategory.category.defaultTranslation.title }}
  </h1>
  <QuestionsList
    class="bg-white rounded-lg bg-opacity-80 p-6 mb-6">
    <QuestionsListItem
      v-for="projectQuestion in projectCategoryQuestions"
      :key="projectQuestion.id"
      :projectQuestion="projectQuestion"
      @updateAnswer="updateAnswer($event, projectQuestion)"
    >
    </QuestionsListItem>
  </QuestionsList>
  <SurveyCategoryAnswer
    class="mb-6"
    :value="projectCategory.answer.value"
    @updateCategoryAnswer="updateCategoryAnswer($event, projectCategory.answer)"
  />
</template>

<script>
import QuestionsList from '@/components/survey/QuestionsList.vue'
import QuestionsListItem from '@/components/survey/QuestionsListItem.vue'
import SurveyCategoryAnswer from '@/components/survey/SurveyCategoryAnswer.vue'
import { useProjectQuestions } from '@/store/services/project-questions'
import { useQuestions } from '@/store/services/questions'
import { useProjectQuestionAnswers } from '@/store/services/project-question-answers'
import { useStore } from '@/store/store.pinia'
import { useProjectCategoryAnswers } from '@/store/services/project-category-answers'

export default {
  name: 'SurveyStepCategoryQuestions',
  setup () {
    const projectQuestionStore = useProjectQuestions()
    const questionStore = useQuestions()
    const store = useStore()
    const projectQuestionAnswerStore = useProjectQuestionAnswers()
    const projectCategoryAnswers = useProjectCategoryAnswers()
    return {
      projectQuestionStore,
      questionStore,
      store,
      projectQuestionAnswerStore,
      projectCategoryAnswers,
    }
  },
  components: {
    QuestionsList,
    QuestionsListItem,
    SurveyCategoryAnswer,
  },
  props: {
    projectCategory: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      projectQuestions: [],
      categoryAnswers: [],
    }
  },
  watch: {
    'category.id' () {
      this.getQuestions()
    },
  },
  async mounted () {
    try {
      this.loading = true
      await this.getQuestions()
      this.loading = false
    } catch (error) {
      this.loading = false
      this.errorMessage = 'Kunne ikke hente spørgsmål'
    }
  },
  methods: {
    async updateAnswer (value, projectQuestion) {
      projectQuestion.answer.value = value
      const r = await projectQuestion.answer.save()
      projectQuestion.answer.id = r.id
      this.store.setUpdateScores()
    },
    async updateCategoryAnswer (value, categoryAnswer) {
      categoryAnswer.value = value
      const r = await categoryAnswer.save()
      categoryAnswer.id = r.id
    },
    async getQuestions () {
      this.projectQuestions = await this.projectQuestionStore.find({
        query: {
          projectId: this.$route.params.projectId,
          projectCategoryId: this.projectCategory.id,
        },
      })
    },
    async getCategoryAnswers () {
      this.categoryAnswers = await this.projectCategoryAnswers.find({
        query: {
          projectCategoryId: this.projectCategory.id,
        },
      })
    },
  },
  computed: {
    projectCategoryQuestions () {
      return this.projectQuestions.filter(
        (projectQuestion) => projectQuestion.project_category.categoryId === this.projectCategory.category.id,
      )
    },
  },
}
</script>

<style>

</style>
