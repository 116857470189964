import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import configPlugin, { config } from './config'
import './index.css'
import { createI18n } from 'vue-i18n'
import appTexts from './i18n/main'
import { pinia } from './store/store.pinia'
import dayjs from './dayjs'

(async () => {
  // Get frontend texts from backend if exists
  const messages = await fetch(`${config.apiBaseUrl}/frontend-texts/`)
    .then(response => response.json())
    .then(data => {
      if (data.code === 404 || data.length === 0) {
        fetch(`${config.apiBaseUrl}/frontend-texts`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            content: appTexts,
          }),
        })
        return appTexts
      } else {
        return data[0].content
      }
    })
  const i18n = createI18n({
    locale: 'no_NO',
    fallbackLocale: 'no_NO',
    messages: await messages,
  })
  createApp(App).use(configPlugin).use(dayjs).use(pinia).use(router).use(i18n).mount('#app')
})()
