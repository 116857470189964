<template>
  <div class="range">
    <label
      v-for="value in [1, 2, 3, 4, 5]" :key="`${inputId}_${value}`"
      class="radio-button text-primary"
    >
      <span class="radio__input">
        <input type="radio" :id="`${inputId}_radio_${value}`" :name="`${inputId}_range-value`" :value="value" v-model="picked" :disabled="disabled">
        <span class="radio__control"></span>
      </span>
      <span class="radio__label block lg:hidden text-center pt-2" for="radio-1">{{labels[value - 1]}}</span>
    </label>
  </div>
</template>

<script>
/* import anime from 'animejs/lib/anime.es.js' */

export default {
  name: 'baseInputRating',
  props: {
    inputId: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      default: null,
    },
    labels: {
      type: Array,
      default () {
        return ['I svært liten grad', 'I liten grad', 'I noen grad', 'I høy grad', 'I svært høy grad']
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  emits: ['answerChanged'],
  computed: {
    picked: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('answerChanged', value)
      },
    },
  },
  methods: {},
}
</script>

<style scoped>
.range {
  display: flex;
  justify-content: space-between;
  opacity: 1;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
.radio-button {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 3.5rem;
  font-size: 30px;
}

.radio__label {
  font-size: 12px;
  color: #333;
}

/* .radio__input {
  margin-bottom: 8px;
} */
.radio__input input {
    display: none;
  }
.radio__input input:focus + .radio__control {
  box-shadow: 0 0 6px rgba(0,0,0,.8);
}

.radio__control {
  display: grid;
  place-items: center;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 2px solid #333;
}

input + .radio__control::before {
  content: "";
  width: .5em;
  height: .5em;
  box-shadow: inset .5em .5em currentColor;
  border-radius: 50%;
  transition: 100ms transform ease-in-out;
  transform: scale(0);
}
input:checked + .radio__control::before {
  transform: scale(1.2);
}
</style>
