<template>
  <vue-final-modal
    v-bind="$attrs"
    classes="modal-container"
    :content-class="`modal-content ${dynClass}`"
    :modelValue="modelValue"
    @update:modelValue="updateModelValue"
  >
    <span class="modal__title"
      :class="size === 'full' ? 'text-black' : ''"
    >
      <slot name="title">{{ $t('Survey.PopupSaveToProfile.Title') }}</slot>
    </span>
    <div class="modal__content">
      <div v-if="formState === 'loading'" class="relative h-20">
        <BaseLoader />
      </div>
      <div v-if="formState === 'succesfull'" class="text-4xl w-full h-full flex justify-center items-center">
        {{ succesMessage }}
        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
          <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
        </svg>
      </div>
      <template v-if="formState === 'normal'">
        <div class="space-y-4">
          <!-- <h3 class="text-xl">{{ $t('Survey.PopupSaveToProfile.You must have a profile to save your results') }}</h3> -->
          <div class="mb-6">
            <input
              v-model="projectTitle"
              :placeholder="$t('Survey.PopupSaveToProfile.Project title')"
              class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
          </div>
          <ul class="flex space-x-2 text-xl">
            <li
              class="border-solid border-gray-500 cursor-pointer p-2"
              :class="content === 'login' ? 'bg-gray-light' : ''"
              @click="content = 'login'"
            >
              {{ $t('Survey.PopupSaveToProfile.TabLogin') }}
            </li>
            <li
              class="border-solid border-gray-500 cursor-pointer p-2"
              :class="content === 'create' ? 'bg-gray-light' : ''"
              @click="content = 'create'"
            >
              {{ $t('Survey.PopupSaveToProfile.TabSignUp') }}
            </li>
          </ul>
          <Login
            :show-labels="false"
            v-if="content === 'login'"
            style="padding: 0; margin: 0; min-height: initial;"
            :is-embedded="true"
            :submitted="submittedLogin"
            :project-title="projectTitle"
            :project-id="projectId"
          />
          <Create
            v-if="content === 'create'"
            style="padding: 0; margin: 0; min-height: initial;"
            :is-embedded="true"
            :submitted="submittedCreate"
            :project-title="projectTitle"
            :project-id="projectId"
          />
        </div>
      </template>
    </div>
    <div class="modal__action" v-if="formState === 'normal'">
      <slot name="actions">
        <BaseButton @click="$emit('update:modelValue', false)">{{ $t('Survey.PopupSaveToProfile.Cancel') }}</BaseButton>
        <BaseButton primary @click="submit">{{ confirmLabel }}</BaseButton>
      </slot>
    </div>
    <!--
    <BaseButton
      v-if="formState === 'normal'"
      :layout="'icon-only'"
      :icon="'Close'"
      class="modal__close"
      :class="size === 'full' ? 'text-black' : ''"
      @click="$emit('update:modelValue', false)"
    ></BaseButton> -->
  </vue-final-modal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import BaseButton from '@/components/shared/baseUi/BaseButton'
import BaseLoader from '@/components/shared/baseUi/BaseLoader.vue'
import Login from '../user/Login.vue'
import Create from '../user/Create.vue'
import useAuth from '@/store/store.auth'
import useGuestAuth from '@/store/store.guestAuth'
import { useStore } from '@/store/store.pinia'

export default {
  name: 'SaveToProfileModal',
  setup () {
    const auth = useAuth()
    const guestAuth = useGuestAuth()
    const store = useStore()
    return {
      auth,
      guestAuth,
      store,
    }
  },
  inheritAttrs: false,
  emits: ['update:modelValue', 'confirm'],
  components: {
    BaseButton,
    BaseLoader,
    VueFinalModal,
    Login,
    Create,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    formState: {
      type: String, // loading || normal || saved || error
      default: 'normal',
    },
    succesMessage: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'md',
    },
    projectId: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      content: 'login',
      submittedCreate: false,
      submittedLogin: false,
      projectTitle: null,
    }
  },
  mounted () {
    this.projectTitle = this.store.currentProjectTitle
  },
  watch: {
    modelValue: {
      immediate: true,
      handler (newVal) {},
    },
  },
  computed: {
    dynClass () {
      if (this.size === 'md') return 'bg-white min-w-90 md:min-w-1/2 lg:min-w-1/4'
      if (this.size === 'full') return 'bg-white min-w-full min-h-full'
      else return ''
    },
    confirmLabel () {
      if (this.content === 'login') {
        return this.$t('Survey.PopupSaveToProfile.Login')
      } else {
        return this.$t('Survey.PopupSaveToProfile.Create')
      }
    },
  },
  methods: {
    updateModelValue (val) {
      this.$emit('update:modelValue', val)
    },
    close () {
      this.$emit('update:modelValue', false)
    },
    submit () {
      if (this.content === 'login') {
        this.submittedLogin = !this.submittedLogin
      } else {
        this.submittedCreate = !this.submittedCreate
      }
    },
  },
}
</script>

<style scoped>
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;
  padding: 1rem;
  /* border: 1px solid #e2e8f0; */
  border-radius: 0.25rem;
  /* background: #fff; */
  max-width: 30%;
  min-width: 30%;
}
.modal__title {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 400;
}
.modal__content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem 0;
}
.modal__content > * {
  display: block;
  margin-bottom: 1rem;
}
.modal__action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem 0 0;
}
.modal__action button {
  margin-bottom: 0;
}
.modal__action > *:not(:last-child) {
  margin-right: 1rem;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.dark-mode div ::v-deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 0 0 0 1rem;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
</style>
