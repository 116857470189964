import { defineStore, BaseModel } from '../store.pinia'
import { api } from '../../feathers'

export class FrontendTexts extends BaseModel {
  static instanceDefaults () {
    return {
      content: null,
    }
  }
}

const servicePath = 'frontend-texts'
export const useFrontendTexts = defineStore({ servicePath, Model: FrontendTexts })

api.service(servicePath).hooks({})
