import { defineStore, BaseModel } from '../store.pinia'
import { api } from '../../feathers'

export class ContentTranslation extends BaseModel {
  static instanceDefaults () {
    return {
      value: '',
      contentId: null,
      localeId: 1,
    }
  }
}

const servicePath = 'content-translations'
export const useContentTranslations = defineStore({ servicePath, Model: ContentTranslation })

api.service(servicePath).hooks({})
