import { createPinia } from 'pinia'
import { storePlugin } from 'pinia-plugin-store'
import { setupFeathersPinia } from 'feathers-pinia'
import { api } from '../feathers'
import { useStorage } from '@vueuse/core'

const pinia = createPinia()
const plugin = storePlugin({
  stores: ['guestAuth'],
  // stores: ['service.guests'],
})
pinia.use(plugin)
export { pinia }

export const { defineStore, BaseModel } = setupFeathersPinia({
  clients: { api },
  idField: 'id',
})

export const useStore = defineStore('main', {
  state: () => ({
    headerMode: 'normal',
    cmsLocale: 'no_No',
    currentProjectId: useStorage('currentProjectId', null, undefined, {
      serializer: {
        read: (v) => v ? parseInt(v) : null,
        write: (v) => v,
      },
    }),
    currentProjectTitle: useStorage('currentProjectTitle', null),
    updateScores: false,
  }),

  actions: {
    setHeaderMode (newMode) {
      this.headerMode = newMode
    },
    setCurrentProjectId (id) {
      this.currentProjectId = parseInt(id)
    },
    setCurrentProjectTitle (title) {
      this.currentProjectTitle = title
    },
    resetCurrentProject () {
      this.currentProjectId = null
    },
    setUpdateScores () {
      this.updateScores = !this.updateScores
    },
  },
})
