<template>
  <div
    id="slide-menu-container"
    class="fixed top-0 bottom-0 w-88 pt-16 bg-primary transition-all"
    :style="'right: -' + leftEdgePosition + 'px;'"
  >
    <ul class="p-8 text-white space-y-2 divide-y divide-white">
      <li class="p-2" @click="$emit('clickedItem')">
        <router-link to="/">{{ $t('Header.Home') }}</router-link>
      </li>
      <li class="p-2" @click="$emit('clickedItem')">
        <router-link to="/survey">{{ $t('Header.Start survey') }}</router-link>
      </li>
      <li v-if="loggedIn" class="p-2" @click="$emit('clickedItem')">
        <router-link to="/projects">{{ $t('Header.Projects') }}</router-link>
      </li>
      <li class="p-2 cursor-pointer" @click="$emit('clickedItem')" data-cy="menuLoginOut">
        <span v-if="loggedIn" @click="logout">{{ $t('Header.Logout') }}</span>
        <span v-if="!loggedIn" @click="login">{{ $t('Header.Login') }}</span>
      </li>
      <li class="p-2" @click="$emit('clickedItem')">
        <router-link to="/about/bygg21">{{ $t('Header.About Bygg21') }}</router-link>
      </li>
      <li class="p-2" @click="$emit('clickedItem')">
        <router-link to="/about/gronn-byggallianse">{{ $t('Header.About Grønn Byggallianse') }}</router-link>
      </li>
      <li class="p-2" @click="$emit('clickedItem')">
        <router-link to="/about/link-arkitektur">{{ $t('Header.About LINK Arkitektur') }}</router-link>
      </li>
      <li v-if="isAdmin" class="p-2" @click="$emit('clickedItem')">
        <router-link to="/cms">{{ $t('Header.CMS') }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import useAuth from '@/store/store.auth'

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      menuWidth: 0,
    }
  },
  setup () {
    const auth = useAuth()
    return {
      auth,
    }
  },
  emits: ['clickedItem'],
  computed: {
    leftEdgePosition () {
      console.log(this.menuWidth)
      if (this.active) {
        return 0
      } else {
        return this.menuWidth
      }
    },
    loggedIn () {
      return this.auth.isAuthenticated
    },
    isAdmin () {
      return this.auth.isAuthenticated && this.auth.user.roleId === 2
    },
  },
  mounted () {
    const containerEl = document.getElementById('slide-menu-container')
    this.menuWidth = containerEl.scrollWidth
  },
  methods: {
    logout () {
      this.auth.logout()
      location.href = '/'
    },
    login () {
      this.$router.push('/user/login')
    },
  },
}
</script>

<style>
#slide-menu-container a {
  color: white;
}
</style>
