// import { api } from '@/feathers'

const downloadProject = () => {
  const getChart = () => {
    const canvas = document.querySelector('[data-cy=rosechart]')
    if (canvas) {
      const data = canvas.toDataURL('image/png')
      return data.replace('data:image/png;base64,', '')
    } else {
      return ''
    }
  }

  const doDownload = async (url, projectId, name, type) => {
    try {
      const headers = {
        Accept: type,
        'Content-Type': 'application/json',
      }
      if (localStorage.getItem('user-jwt')) {
        headers.Authorization = `Bearer ${localStorage.getItem('user-jwt')}`
      } else if (localStorage.getItem('guest-jwt')) {
        headers['Authorization-Guest'] = `Bearer ${localStorage.getItem('guest-jwt')}`
      }
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          projectId: projectId,
          download: true,
          chart: type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? getChart() : null,
        }),
      })
      const blob = await response.blob()
      const ext = type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? 'docx' : 'csv'
      // const filename = encodeURIComponent(`${name}.${ext}`)
      const filename = `${name}.${ext}`
      getFile(blob, filename, type)
    } catch (e) {
      console.log(e)
    }
  }

  const getFile = function (content, fileName, mimeType) {
    const a = document.createElement('a')
    mimeType = mimeType || 'application/octet-stream'
    if (navigator.msSaveBlob) { // IE10
      navigator.msSaveBlob(new Blob([content], {
        type: mimeType,
      }), fileName)
    } else if (URL && 'download' in a) { // html5 A[download]
      a.href = URL.createObjectURL(new Blob([content], {
        type: mimeType,
      }))
      a.setAttribute('download', fileName)
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    } else {
      location.href = 'data:application/octet-stream,' + encodeURIComponent(content) // only this mime type is supported
    }
  }

  return { getChart, doDownload, getFile }
}

export default downloadProject
