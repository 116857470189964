<template>
  <div class="py-4">
    <ul class="flex flex-col" data-cy="cmsCategoriesList" v-if="!loading">
      <li
        class="grid mb-2 p-2 font-bold"
        :key="'action_list_header'"
        style="grid-template-columns: 4rem 4rem 4rem 1fr 4fr 16rem;"
      >
        <h3></h3>
        <h3>{{$t('Shared.icon')}}</h3>
        <h3>{{$t('Shared.color')}}</h3>
        <h3>{{$t('Shared.title')}}</h3>
        <h3>{{$t('Shared.description')}}</h3>
        <h3 class="text-right pr-2">{{$t('Shared.controls')}}</h3>
      </li>
      <CmsCategoriesRow
        v-for="cat in categories" :key="cat.id"
        :cat="cat"
        @edit="editCategory"
        @edit-question="editQuestion"
        @add-question="addQuestion"
        @add-example="addExample"
      />
    </ul>
    <div v-if="loading">
      {{ $t('Cms.Shared.Fetching principles') }}
    </div>
    <ModalEditCategory v-if="showEditCategoryModal"
      :category="currentCat"
      :model-value="showEditCategoryModal"
      @update:model-value="showEditCategoryModal = $event"
      @data-updated="getCategories"
    />
    <ModalEditQuestion v-if="showEditQuestionModal"
      :question="currentQuestion"
      :model-value="showEditQuestionModal"
      @update:model-value="showEditQuestionModal = $event"
      @data-updated="getCategories"
    />
    <ModalAddQuestion v-if="showAddQuestionModal"
      :category-id="currentCat.id"
      :model-value="showAddQuestionModal"
      @update:model-value="showAddQuestionModal = $event"
      @data-updated="getCategories"
    />
    <ModalAddExample v-if="showAddExampleModal"
      :question="currentQuestion"
      :model-value="showAddExampleModal"
      @update:model-value="showAddExampleModal = $event"
      @data-updated="getCategories"
    />
  </div>
</template>

<script>
import CmsCategoriesRow from '@/components/cms/CmsCategoriesRow'
import { useCategories } from '@/store/services/categories'
import ModalEditCategory from '@/components/cms/ModalEditCategory.vue'
import ModalEditQuestion from '@/components/cms/ModalEditQuestion.vue'
import ModalAddQuestion from '@/components/cms/ModalAddQuestion.vue'
import ModalAddExample from '@/components/cms/ModalAddExample.vue'

export default {
  data () {
    return {
      loading: false,
      showEditCategoryModal: false,
      currentCat: {},
      showEditQuestionModal: false,
      currentQuestion: {},
      showAddQuestionModal: false,
      showAddExampleModal: false,
      categories: [],
    }
  },
  setup () {
    const categoryStore = useCategories()
    return {
      categoryStore,
    }
  },
  components: {
    CmsCategoriesRow,
    ModalEditCategory,
    ModalEditQuestion,
    ModalAddQuestion,
    ModalAddExample,
  },
  async mounted () {
    this.loading = true
    await this.getCategories()
    this.loading = false
  },
  methods: {
    editCategory (category) {
      this.currentCat = category
      this.showEditCategoryModal = true
    },
    editQuestion (question) {
      this.currentQuestion = question
      this.showEditQuestionModal = true
    },
    addQuestion (category) {
      console.log('adding question')
      this.currentCat = category
      this.showAddQuestionModal = true
    },
    addExample (question) {
      console.log('adding example')
      this.currentQuestion = question
      this.showAddExampleModal = true
    },
    async getCategories () {
      this.categories = await this.categoryStore.find({
        query: {},
      })
      if (this.showEditCategoryModal) { // Update current category
        const catId = this.currentCat.id
        this.currentCat = this.categories.find(cat => cat.id === catId)
      }
    },
  },
}
</script>

<style scoped>

</style>
