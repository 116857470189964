<template>
  <BaseFormModal
    :modelValue="modelValue"
    @update:modelValue="updateModelValue"
    :modalTitle="$t('Shared.add')"
    size="lg"
    :formFields="[
      { type: 'hidden', name: 'id', value: 1 },
      { type: 'textInput', name: 'title', label: 'title', value: '', placeholder: 'titel' },
    ]"
    @confirm="saveExample"
  />
</template>

<script>
import BaseFormModal from '@/components/shared/baseUi/BaseFormModal'
import { useStore } from '@/store/store.pinia'
import { useQuestionExamples } from '@/store/services/question-examples'
import { useQuestionExampleTranslations } from '@/store/services/question-example-translations'

export default {
  components: {
    BaseFormModal,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    question: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:modelValue', 'data-updated'],
  data () {
    return {
      saving: false,
    }
  },
  setup () {
    const store = useStore()
    const exampleStore = useQuestionExamples()
    const exampleTranslationStore = useQuestionExampleTranslations()
    return {
      store,
      exampleStore,
      exampleTranslationStore,
    }
  },
  methods: {
    updateModelValue (val) {
      this.$emit('update:modelValue', val)
    },
    closeModal (close) {
      this.$emit('update:modelValue', false)
    },
    async saveExample (val) {
      this.saving = true
      // Create
      const example = await this.exampleStore.create({
        questionId: this.question.id,
      })
      // Create translation data
      await this.exampleTranslationStore.create({
        questionExampleId: example.id,
        title: val.title,
      })
      this.$emit('data-updated')
      this.saving = false
      this.closeModal()
    },
  },
  watch: {
    modelValue (newValue) {
      if (newValue) {
        this.saving = false
      }
    },
  },
}
</script>

<style scoped>
button:not(:last-child) {
  margin-right: 1rem;
}
</style>
