import { defineStore, BaseModel } from '../store.pinia'
import { api } from '../../feathers'

export class QuestionExampleTranslation extends BaseModel {
  static instanceDefaults () {
    return {
      title: null,
      questionId: null,
      localeId: null,
    }
  }
}

const servicePath = 'question-example-translations'
export const useQuestionExampleTranslations = defineStore({ servicePath, Model: QuestionExampleTranslation })

api.service(servicePath).hooks({})
