import { defineStore, BaseModel } from '../store.pinia'
import { api } from '../../feathers'
import { ProjectQuestionAnswer } from './project-question-answers'

export class ProjectQuestion extends BaseModel {
  static instanceDefaults () {
    return {
      projectId: null,
      questionId: null,
    }
  }

  static setupInstance (data, { models }) {
    if (data.project_question_answer) {
      data.answer = new ProjectQuestionAnswer(data.project_question_answer)
    } else {
      data.answer = new ProjectQuestionAnswer({
        projectQuestionId: data.id,
      })
    }
    return data
  }
}

const servicePath = 'project-questions'
export const useProjectQuestions = defineStore({ servicePath, Model: ProjectQuestion })

api.service(servicePath).hooks({})
