import { defineStore, BaseModel } from '../store.pinia'
import { api } from '../../feathers'
import { ContentTranslation } from './content-translations'

export class Content extends BaseModel {
  static instanceDefaults () {
    return {
      position: 0,
      page: null,
      contentType: 'text',
    }
  }

  static setupInstance (data, { models }) {
    if (data.defaultTranslation) {
      data.defaultTranslation = new ContentTranslation(data.defaultTranslation)
    }
    return data
  }
}

const servicePath = 'content'
export const useContent = defineStore({ servicePath, Model: Content })

api.service(servicePath).hooks({})
