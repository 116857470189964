<template>
  <div class="pb-1">
    <ul class="flex flex-col" data-cy="cmsQuestionExamplesList" v-if="!loading">
      <li v-for="(example, idx) in examples" :key="idx" class="grid items-center p-2 bg-white bg-opacity-80 mb-1" style="grid-template-columns: 5fr 2fr;">
        <div>
          <span>{{ example.defaultTranslation.title }}</span>
        </div>
        <div class="flex justify-end space-x-4">
          <BaseButton
            :label="$t('Shared.edit') + ' ' + $t('Shared.example')"
            @click="editExample(example)"
            data-cy="cmsEditExampleButton"
          />
          <BaseButton
            :label="$t('Shared.delete')"
            @click="deleteExample(example)"
            data-cy="cmsDeleteExampleButton"
          />
        </div>
      </li>
    </ul>
    <div v-if="loading">
      {{ $t('Cms.Shared.Fetching content') }} }}}
    </div>
    <ModalEditExample v-if="showEditExampleModal"
      :example="currentExample"
      :model-value="showEditExampleModal"
      @update:model-value="showEditExampleModal = $event"
      @data-updated="getExamples"
    />
  </div>
</template>

<script>
import { useQuestionExamples } from '@/store/services/question-examples'
import BaseButton from '@/components/shared/baseUi/BaseButton.vue'
import ModalEditExample from '@/components/cms/ModalEditExample.vue'

export default {
  props: {
    questionId: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      loading: false,
      examples: [],
      currentPage: [],
      showEditExampleModal: false,
    }
  },
  setup () {
    const exampleStore = useQuestionExamples()
    return {
      exampleStore,
    }
  },
  components: {
    BaseButton,
    ModalEditExample,
  },
  async mounted () {
    this.loading = true
    await this.getExamples()
    this.loading = false
  },
  methods: {
    editExample (example) {
      this.currentExample = this.examples.filter(item => item.id === example.id)[0]
      this.showEditExampleModal = true
    },
    async deleteExample (example) {
      await example.remove()
      this.getExamples()
    },
    async getExamples () {
      const query = {}
      if (this.questionId) {
        query.questionId = this.questionId
      }
      this.examples = await this.exampleStore.find({
        query,
      })
      console.log('hep', this.questionId)
    },
  },
}
</script>

<style scoped>

</style>
