import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'
import ProjectsView from '../views/Projects.vue'
import ProjectView from '../views/Project.vue'
import SurveyView from '../views/Survey.vue'
import Cms from '../views/Cms.vue'
import { useStore } from '@/store/store.pinia'
import UserLogin from '../views/UserLogin.vue'
import UserCreate from '../views/UserCreate.vue'
import UserLogout from '../views/UserLogout.vue'
import useAuth from '@/store/store.auth'
import useGuestAuth from '@/store/store.guestAuth'
import About from '../views/About.vue'
import Guide from '../views/Guide.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/projects',
    name: 'projects',
    component: ProjectsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/project/:projectId',
    name: 'project',
    props: route => ({ projectId: Number(route.params.projectId) }),
    component: ProjectView,
  },
  {
    path: '/survey/:projectId?/:stepIdx?',
    name: 'survey',
    component: SurveyView,
  },
  {
    path: '/cms',
    name: 'cms',
    component: Cms,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/user/login',
    name: 'UserLogin',
    component: UserLogin,
  },
  {
    path: '/user/create',
    name: 'UserCreate',
    component: UserCreate,
  },
  {
    path: '/user/validate/:hash/:email',
    name: 'UserValidate',
    component: () => import(/* webpackChunkName: "uservalidate" */ '../views/UserValidate.vue'),
  },
  {
    path: '/user/logout',
    name: 'UserLogout',
    component: UserLogout,
  },
  {
    path: '/user/created',
    name: 'UserCreated',
    component: () => import(/* webpackChunkName: "uservalidate" */ '../views/UserCreated.vue'),
  },
  {
    path: '/about/:page',
    name: 'About',
    component: About,
  },
  {
    path: '/guide',
    name: 'Guide',
    component: Guide,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
router.beforeEach(async (to, from, next) => {
  const store = useStore()
  if (to.name === 'survey') {
    store.setHeaderMode('survey')
  } else {
    store.setHeaderMode('normal')
  }
  const auth = useAuth()
  const guestAuth = useGuestAuth()
  if (localStorage.getItem('user-jwt')) {
    try {
      const authResponse = await auth.authenticate({
        strategy: 'jwt',
        accessToken: localStorage.getItem('user-jwt'),
      })
      if (!authResponse.user) {
        auth.logout()
        location.href = '/user/login'
      }
      if (to.meta.requiresAdmin) {
        if (auth.user.roleId !== 2) {
          next(false)
        }
      }
    } catch (e) {
      auth.logout()
      next('/user/login')
      return
    }
  } else if (localStorage.getItem('guest-jwt')) {
    try {
      const authResponse = await guestAuth.authenticate({
        strategy: 'guest',
        accessToken: localStorage.getItem('guest-jwt'),
      })
      if (!authResponse.guest) {
        guestAuth.logout()
      }
    } catch (e) {
      guestAuth.logout()
    }
  }
  if (to.meta.requiresAuth && !auth.isAuthenticated) {
    next('/user/login')
  } else {
    next()
  }
})

export default router
