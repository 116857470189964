<template>
  <div class="w-full" v-if="isReady">
    <SurveyNavigation
      class="hidden md:flex md:fixed top-32 overflow-y-auto overflow-x-hidden bottom-6 left-6 w-36"
      :categories="surveyCategories"
      :currentStepIndex="currentStepIndex"
      :scores="scores"
      @gotoStep="gotoStep"
    />
    <SurveyContent class="w-full overflow-y-scroll pb-20 pt-12 md:pt-32 px-6 md:px-52 lg:px-64">
      <div class="md:hidden text-sm">
        <div v-if="currentStepIndex === 0">{{ $t('Shared.survey') }} | {{ $t('Shared.intro') }}</div>
        <div v-if="currentStepIndex > 0 && currentStepIndex < 21">{{ $t('Shared.survey') }} | {{ $t('Shared.category') }} {{ Math.floor((currentStepIndex - 1) / 2) + 1 }} af 10</div>
        <div v-if="currentStepIndex === 21">{{ $t('Shared.survey') }}</div>
      </div>
      <SurveyStepMainIntro
        v-if="currentStepType === 'mainIntro'"
      ></SurveyStepMainIntro>
      <SurveyStepCategoryIntro
        v-if="currentStepType === 'categoryIntro'"
        :project-category="surveyCategories[currentStep.categoryIndex]"
      ></SurveyStepCategoryIntro>
      <SurveyStepCategoryQuestions
        v-if="currentStepType === 'categoryQuestions'"
        :project-category="surveyCategories[currentStep.categoryIndex]"
      ></SurveyStepCategoryQuestions>
      <SurveyStepMainOutro
        v-if="currentStepType === 'mainOutro'"
      ></SurveyStepMainOutro>
    </SurveyContent>
    <SurveyStepNavigation
      class="fixed bottom-0 md:bottom-6 md:right-6 w-full md:w-32 z-30"
      @next="nextStep"
      @prev="prevStep"
      @close="closeSurvey"
      :first="currentStepType === 'mainIntro'"
      :last="currentStepType === 'mainOutro'"
    />
    <NewProjectTitleModal
      v-model="showTitleModal"
      :project-id="store.currentProjectId"
      @confirm="updateTitle"
    />
  </div>
</template>

<script>
import SurveyNavigation from '@/components/survey/SurveyMainNavigation.vue'
import SurveyContent from '@/components/survey/SurveyContent.vue'
import SurveyStepNavigation from '@/components/survey/SurveyStepNavigation.vue'
import SurveyStepCategoryIntro from '@/components/survey/SurveyStepCategoryIntro.vue'
import SurveyStepCategoryQuestions from '@/components/survey/SurveyStepCategoryQuestions.vue'
import SurveyStepMainIntro from '@/components/survey/SurveyStepMainIntro.vue'
import SurveyStepMainOutro from '@/components/survey/SurveyStepMainOutro.vue'
// import getCategories from '@/api/getCategories'
import guest from '@/composables/guest'
import project from '@/composables/project'
import { useProjectCategories } from '@/store/services/project-categories'
import { useCategories } from '@/store/services/categories'
import { useStore } from '@/store/store.pinia'
import useAuth from '@/store/store.auth'
import { useRoute } from 'vue-router'
import { ref } from 'vue'
import NewProjectTitleModal from '@/components/survey/ModalNewProjectTitle.vue'

export default {
  name: 'Survey',
  components: { SurveyNavigation, SurveyContent, SurveyStepNavigation, SurveyStepCategoryIntro, SurveyStepCategoryQuestions, SurveyStepMainIntro, SurveyStepMainOutro, NewProjectTitleModal },
  data () {
    return {
      surveyCategories: [],
      steps: [{ stepType: 'mainIntro' }],
      currentStepIndex: 0,
      isReady: false,
      showTitleModal: false,
    }
  },
  setup (props, context) {
    const { createGuest } = guest()
    const { createProject, updateProjectTitle } = project()
    const categoryStore = useCategories()
    const projectCategoryStore = useProjectCategories()
    const auth = useAuth()
    const store = useStore()
    const route = useRoute()
    const scores = ref([])

    const getProjectCategoryScores = async () => {
      if (!route.params.projectId && !store.currentProjectId) {
        scores.value = []
      } else {
        scores.value = await projectCategoryStore.find({
          query: {
            projectId: route.params.projectId || store.currentProjectId,
            getScores: true,
          },
        })
      }
    }

    /*
    store.$subscribe((mutation, state) => {
      console.log(state)
      if (mutation.events.key === 'updateScores' && mutation.events.newValue !== mutation.events.oldValue) {
        getProjectCategoryScores()
      }
    })
    */

    return {
      createGuest,
      createProject,
      projectCategoryStore,
      categoryStore,
      store,
      auth,
      scores,
      updateProjectTitle,
      getProjectCategoryScores,
    }
  },
  computed: {
    currentStep () {
      return this.steps[this.currentStepIndex]
    },
    currentStepType () {
      return this.currentStep.stepType
    },
  },
  watch: {
    '$route.params' (to, from) {
      this.currentStepIndex = parseInt(to.stepIdx)
    },
    'store.updateScores' (to, from) {
      if (to !== from) {
        this.getProjectCategoryScores()
      }
    },
  },
  async mounted () {
    await this.createGuest()
    this.getProjectCategoryScores()
    if (this.store.currentProjectId === null) {
      if (this.$route.params.projectId) {
        this.store.setCurrentProjectId(this.$route.params.projectId)
      } else {
        const project = await this.createProject()
        this.store.setCurrentProjectId(project.id)
      }
    }
    await this.getCategories()
    await this.makeSteps()
    if (this.auth.isAuthenticated && !this.$route.params.projectId && this.store.currentProjectId) {
      this.showTitleModal = true
    }
    if (this.$route.params.projectId && this.$route.params.stepIdx) {
      this.currentStepIndex = parseInt(this.$route.params.stepIdx)
    }
    this.isReady = true
  },
  methods: {
    async updateTitle (title) {
      await this.updateProjectTitle(this.store.currentProjectId, title)
      this.showTitleModal = false
    },
    async getCategories () {
      this.surveyCategories = await this.projectCategoryStore.find({
        query: {
          projectId: this.$route.params.projectId || this.store.currentProjectId,
        },
      })
    },
    closeSurvey () {
      const currentProjectId = this.store.currentProjectId
      this.store.resetCurrentProject()
      if (this.auth.isAuthenticated) {
        this.$router.push(`/project/${currentProjectId}`)
      } else {
        if (window.confirm(this.$t('Survey.ConfirmDeleteAnswers'))) {
          this.$router.push({ name: 'home' })
        }
      }
    },
    async makeSteps () {
      this.surveyCategories.forEach((category, index) => {
        this.steps.push({ stepType: 'categoryIntro', categoryIndex: index })
        this.steps.push({ stepType: 'categoryQuestions', categoryIndex: index })
      })
      this.steps.push({ stepType: 'mainOutro' })
    },
    nextStep () {
      if (this.currentStepIndex < this.steps.length - 1) {
        this.$router.push({
          path: `/survey/${this.$route.params.projectId || this.store.currentProjectId}/${this.currentStepIndex + 1}`,
        })
      }
    },
    prevStep () {
      if (this.currentStepIndex > 0) {
        this.$router.push({
          path: `/survey/${this.$route.params.projectId || this.store.currentProjectId}/${this.currentStepIndex - 1}`,
        })
      }
    },
    gotoStep (step) {
      this.$router.push({
        path: `/survey/${this.$route.params.projectId || this.store.currentProjectId}/${step}`,
      })
    },
  },
}
</script>

<style>

</style>
