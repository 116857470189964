/* eslint-disable quote-props */
const messages = {
  'no_NO': {
    'Shared': {
      'edit': 'Rediger',
      'delete': 'Slett',
      'cancel': 'Avbryt',
      'save': 'Lagre',
      'saving': 'Lagrer',
      'saved': 'Lagret',
      'add': 'Legg til',
      'previous': 'forrige',
      'next': 'neste',
      'close': 'lukk',
      'projects': 'Prosjekter',
      'create': 'opprett',
      'loading': 'Laster inn',
      'rating': 'Vurdering',
      'choose': 'Velg',
      'title': 'Tittel',
      'description': 'Beskrivelse',
      'icon': 'Ikon',
      'color': 'Farge',
      'controls': 'Handlinger',
      'search': 'Søk',
      'category': 'Prinsipp',
      'question': 'spørsmål',
      'example': 'eksempel',
      'messageNoTranslation': 'Text mangler',
      'text': 'Tekst',
      'test': 'Test',
      'survey': 'Spørreskjema',
      'intro': 'Intro',
    },
    'Header': {
      'Home': 'Forside',
      'About Bygg21': 'Om Bygg21',
      'About Grønn Byggallianse': 'Om Grønn Byggallianse',
      'About LINK Arkitektur': 'Om LINK Arkitektur',
      'Support': 'Veiledning',
      'Projects': 'Prosjekter',
      'Start survey': 'Start spørreskjema',
      'CMS': 'CMS',
      'Login': 'Logg inn',
      'Logout': 'Logg ut',
    },
    'Survey': {
      'New': {
        'Title': 'Tittel',
        'Description': 'Du kan gi prosjektet en tittel hvis du vil.',
      },
      'PopupSaveToProfile': {
        'TabLogin': 'Logg inn',
        'TabSignUp': 'Registrere',
        'Title': 'Lagre til profil',
        'Cancel': 'Avbryt',
        'Login': 'Logg inn og lagre',
        'Create': 'Lag profil og lagre',
        'Project title': 'Prosjekt tittel',
        'You must have a profile to save your results': 'Du må ha en profil for å lagre resultatene dine',
      },
      'Questions': {
        'How much will you work for...': 'I hvor høy grad vil du...',
        'What demands will you make and what kind of actions will you take in this area?': 'Hvilke krav vil du stille, og hva slags tiltak vil du gjøre på dette området?',
      },
      'Save on Profile': 'Lagre på profil',
      'Download CSV file': 'Last ned CSV-fil',
      'Download report': 'Last ned rapport',
      'ConfirmDeleteAnswers': 'Lukker du spørreskjema uten å logge inn, mister du svarene dine',
    },
    'Projects': {
      'Shared': {
        'project_title': 'Prosjekttittel',
        'project_number': 'Prosjektnummer',
        'project_description': 'Prosjektbeskrivelse',
        'project_goals': 'Prosjektmålsettinger',
      },
      'Project list view': {
        'Title': 'Mine prosjekter',
        'ButtonNewProject': 'Nytt prosjekt',
        'MessageNoProjects': 'Ingen prosjekter. Legg til et nytt prosjekt.',
        'No name': 'Uten tittel',
      },
      'Project view': {
        'ButtonReport': 'Download rapport (Word)',
        'ButtonEditAnswers': 'Redigér besvarelse',
        'ButtonExportCsv': 'Eksportér (CSV)',
        'ButtonDeleteProject': 'Slet projekt',
        'SectionTitlePrinciples': 'Velg et princip for at se besvarelser',
        'ConfirmDeleteProject': 'Er du sikker på at du vil slette prosjektet?',
      },
      'Edit project popup': {
        'Title': 'Rediger prosjekt',
        'Label01': '@:Projects.Shared.project_title',
        'Label02': '@:Projects.Shared.project_description',
        'Label03': '@:Projects.Shared.project_number',
        'Button01': '@:Shared.cancel',
        'Button02': 'Slett prosjekt',
        'Button03_loading': '@:Shared.saving',
        'Button03': '@:Shared.save',
      },
      'New project popup': {
        'Title': 'Nytt prosjekt',
        'Label01': '@:Projects.Shared.project_title',
        'Label02': '@:Projects.Shared.project_description',
        'Label03': '@:Projects.Shared.project_number',
        'Button01': '@:Shared.cancel',
        'Button02': '@:Shared.create',
      },
      'Share project popup': {
        'Title': 'Del prosjekt',
        'Manchet': 'Skriv emailen på den du ønsker at dele projektet med. Personen får adgang til projektet på lige vilkår med dig. (Men kan dog ikke dele det videre)',
        'Label01': 'E-post adresse',
        'Label02': 'Delt med',
        'Button01': 'Del',
        'Button02': 'Luk',
      },
    },
    'Static pages': {
      'Home': {
        'Manchet': 'Bygg21 har utarbeidet 10 kvalitetsprinsipper for bærekraftige bygg og områder som ser bærekraftige kvaliteter i en helhet. Kvalitetsrosen er et tidligfase verktøy som hjelper deg å definere de overordnede bærekraftmålsettingene ved hjelp av kvalitetsprinsippene.',
        'Description': `Kvalitetsrosen er et digitalt verktøy som er åpent tilgjengelig for alle. Hovedmålgruppen er eiendoms- og boligutviklere. Verktøyet kan brukes som et dialogverktøy mellom ulike parter i en tidlig fase av et byggeprosjekt.  Selskapsledelsen kan også anvende dette som et strategisk verktøy for å synliggjøre sine kvalitetsmål for prosjekteringsgruppen. Spørsmålene bør besvares av prosjektleder og bærekraftsleder, og gjerne i samråd med øverste ledelse.
        Kvalitetsrosen er utviklet i et samarbeid mellom Grønn Byggallianse, LINK Arkitektur og Meetr.`,
        'Card01 title': 'Dine prosjekter',
        'Card01 description': 'Se dine tidligere besvarelser og kvalitetsroser her.',
        'Card01 button': 'Se prosjekter',
        'Card02 title': 'Start spørreskjema',
        'Card02 description': 'Synliggjøre prosjektets kvalitetsmål for bærekraft – kom i gang!',
        'Card02 button': 'Start spørreskjema',
        'Card03 title': 'Veiledning',
        'Card03 description': 'Kvalitetsprinsippene er utviklet som en veiviser for hva man skal tenke på når man skal bygge eller etterspørre bærekraftige bygg og områder.',
        'Card03 button': 'Les mer',
        'Card04 title': 'Kvalitetsprinsippene',
        'Card04 description': 'Når vi snakker om bærekraftige kvaliteter, er vi opptatt av løsninger som ivaretar sosiale, økonomiske og miljømessige aspekter samtidig.',
        'Card04 button': 'Les mer',
        'Card05 title': 'Profil',
        'Card05 description': 'Aenean lectus risus, volutpat vitae elementum a, ullamcorper a arcu.',
        'Card05 button': 'Log in / oprett',
      },
    },
    'Users': {
      'Login view': {
        'MessageLoading': '@:Shared.loading',
        'Title01': 'Logg inn',
        'Title02': 'Logg inn med e-post adresse',
        'Label01': 'E-post adresse',
        'Input01_placeholder': 'Din e-post adresse',
        'Label02': 'Passord',
        'Input02_placeholder': 'Ditt passord',
        'Button01': 'Logg inn',
        'Button01_loading': 'Logger inn',
        'Button02': 'Logg inn med e-post adresse',
        'Button02_loading': '',
        'Link01': 'Oprette ny bruker?',
        'Link02': 'Glemt passord?',
        'Link02_alt': 'Logg inn med passord',
        'MessageEmailSent': 'E-post er sendt',
        'ErrorMessage01': 'Kunne ikke logge på. Vennligst bekreft informasjonen.',
        'ErrorMessage02': 'En feil oppstod. Vær så snill, prøv på nytt.',
      },
      'Signup view': {
        'Title01': 'Oprett ny bruker',
        'Label01': 'Fullt navn',
        'Input01_placeholder': 'Kari Normann',
        'Label02': 'E-post adresse',
        'Input02_placeholder': 'Din e-post adresse',
        'Label03': 'Passord',
        'Input03_placeholder': 'Fritt valg passord',
        'Button01': 'Opprett',
        'ErrorMessage01': 'Domain not allowed',
        'ErrorMessage02': 'Kunne ikke opprette bruker. Kanskje e-posten allerede eksisterer?',
        'ErrorMessage03': 'Du må godta vilkårene når du oppretter en bruker?',
        'Agreement01': 'Jeg har lest og aksepterer',
        'Agreement02': 'personvernerklæringen',
        'Agreement03': 'https://byggalliansen.no/hjem/om-oss/personvernerklaering/',
      },
      'Created view': {
        'User Created': 'Bruker oprettet',
        'Your user was created and you should click on the link we just sent you to activate your account. If you do not receive the email within a few minutes, please check your spam folder.': 'Brukeren din ble opprettet, og du bør klikke på lenken vi nettopp sendte deg for å aktivere kontoen din. Hvis du ikke mottar e-posten innen noen få minutter, sjekk søppelpostmappen din.',
      },
    },
    'Cms': {
      'Main view': {
        'TabTexts': 'Tekster',
        'TabPrinciples': 'Prinsipper og spørsmål',
        'TabPages': 'Sider',
        'TabReport': 'Rapport',
        'TabExamples': 'Eksempler',
      },
      'Shared': {
        'Edit principle': 'Redigere prinsippet',
        'Fetching principles': 'Henter prinsipper',
        'Fetching content': 'Henter innhold',
        'isArchive': 'arkivert',
        'isArchiveQuestion': 'Er det arkivert?',
      },
    },
  },
}

export default messages
