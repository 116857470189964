import { defineAuthStore } from 'feathers-pinia'
import { api as feathersClient } from '../feathers'
import { Guest } from './services/guests'

const useGuestAuth = defineAuthStore({
  feathersClient,
  id: 'guestAuth',
  state: () => ({
    guestId: null,
  }),
  getters: {
    getUser () {
      return this.guestId ? Guest.getFromStore(this.guestId) : null
    },
  },
  actions: {
    handleResponse (response) {
      this.guestId = response.guest.id || response.guest._id
      Guest.addToStore(response.guest)
      return response
    },
    async logout () {
      localStorage.removeItem('guest-jwt')
      this.guestId = null
      this.isAuthenticated = false
      this.accessToken = null
    },
  },
})

export default useGuestAuth
