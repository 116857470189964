import { defineStore, BaseModel } from '../store.pinia'
import { api } from '../../feathers'

export class DataExportTranslation extends BaseModel {
  static instanceDefaults () {
    return {
      text: null,
      dataExportId: null,
      localeId: null,
    }
  }
}

const servicePath = 'data-export-translations'
export const useDataExportTranslations = defineStore({ servicePath, Model: DataExportTranslation })

api.service(servicePath).hooks({})
