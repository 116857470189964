<template>
  <div
    class="md:fixed top-0 left-0 right-0 grid grid-cols-12 pt-6 px-6"
    :class="headerMode === 'survey' ? 'bg-green-lighter' : 'bg-green-lighter'"
  >
    <div class="col-span-1 pt-1 text-secondary">
      <div
        class="text-center cursor-pointer"
        :class="headerMode === 'survey' ? 'text-3xl' : 'text-3xl'"
        @click="$router.push({name: 'home'})"
      >
        KVALITETSROSEN
      </div>
      <!-- <div v-if="headerMode === 'survey'" class="text-xl uppercase">Spørreskjema</div> -->
    </div>
    <!-- <SiteLogo class="col-span-2 cursor-pointer" @click="$router.push({name: 'home'})" /> -->
    <MainNavigation class="col-span-11" />
  </div>
</template>

<script>
import MainNavigation from '@/components/shared/header/MainNavigation.vue'
// import SiteLogo from '@/components/shared/header/SiteLogo.vue'
import { useStore } from '@/store/store.pinia'

export default {
  name: 'SiteHeader',
  setup () {
    const store = useStore()
    return {
      store,
    }
  },
  components: {
    MainNavigation,
    // SiteLogo,
  },
  computed: {
    headerMode () {
      return this.store.headerMode
    },
  },
}
</script>

<style>

</style>
