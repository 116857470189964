import { defineStore, BaseModel } from '../store.pinia'
import { api } from '../../feathers'
import { QuestionExampleTranslation } from './question-example-translations'

export class QuestionExample extends BaseModel {
  static instanceDefaults () {
    return {
      isArchive: false,
      position: 0,
      questionId: null,
    }
  }

  static setupInstance (data, { models }) {
    if (data.defaultTranslation) {
      data.defaultTranslation = new QuestionExampleTranslation(data.defaultTranslation)
    }
    return data
  }
}

const servicePath = 'question-examples'
export const useQuestionExamples = defineStore({ servicePath, Model: QuestionExample })

api.service(servicePath).hooks({})
