<template>
  <ul class="flex flex-col w-32 sm:w-40 text-sm">
    <li
      class="shadow hover:shadow-lg transition-all hover:-translate-y-2px text-sm w-32 sm:w-40 cursor-pointer mb-1 flex align-stretch rounded-t-lg overflow-hidden"
      :class="currentStepIndex === 0 ? 'text-white bg-secondary' : 'bg-white'"
      @click="$emit('gotoStep', 0)"
    >
      <span class="p-2 w-28 sm:w-36 cursor-pointer" @click="$emit('gotoStep', 0)">
        {{ $t('Shared.intro') }}
      </span>
      <span class="w-12 bg-primary space-y-1 flex flex-col justify-stretch items-stretch p-2">
      </span>
    </li>
    <li
      v-for="(category, index) in categories" :key="category.id"
      class="shadow hover:shadow-lg transition-all hover:-translate-y-2px text-sm w-32 sm:w-40 cursor-pointer mb-1 flex align-stretch"
      :class="selectedCategoryIndex === index ? 'text-white bg-secondary' : 'bg-white'"
      @click="$emit('gotoStep', (index * 2) + 1)"
    >
      <span class="p-2 w-28 sm:w-36">
        {{ $t('Shared.category') }} {{ index + 1 }}
        <img :src="`${$appConfig.apiBaseUrl}${category.category.icon}`" class="h-4 inline-block">
      </span>
      <span class="w-12 bg-primary space-y-1 flex justify-center p-2 text-white">
        <span :class="{ bounce: bounces[category.id] }">{{ score(category) }}</span>
      </span>
    </li>
    <li
      class="shadow hover:shadow-lg transition-all hover:-translate-y-2px text-sm w-32 sm:w-40 cursor-pointer mb-1 flex align-stretch rounded-b-lg overflow-hidden"
      :class="currentStepIndex === 21 ? 'text-white bg-secondary' : 'bg-white'"
      @click="$emit('gotoStep', 21)"
    >
      <span class="p-2 w-28 sm:w-36 cursor-pointer" @click="$emit('gotoStep', 0)">
        Opsamling
      </span>
      <span class="w-12 bg-primary flex flex-col justify-stretch items-stretch p-2">
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SurveyNavigation',
  data: () => ({
    bounces: [],
  }),
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
    currentStepIndex: {
      type: Number,
      default: 0,
    },
    scores: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['gotoStep'],
  watch: {
    scores: {
      deep: true,
      handler (newScore, oldScore) {
        for (let i = 0; i < newScore.length; i++) {
          if (newScore[i]?.score !== oldScore[i]?.score) {
            this.bounces[newScore[i].id] = true
            setTimeout(() => {
              this.bounces[newScore[i].id] = false
            }, 300)
          }
        }
      },
    },
  },
  computed: {
    selectedCategoryIndex () {
      return Math.floor((this.currentStepIndex - 1) / 2)
    },
  },
  methods: {
    score (projectCategory) {
      if (this.scores.length === 0) {
        return 0.0
      }
      return parseFloat(this.scores.find(score => score.id === projectCategory.id).score).toFixed(1)
    },
    shorten (text) {
      if (text.length > 45) {
        return text.slice(0, 45) + '...'
      } else {
        return text
      }
    },
  },
}
</script>

<style>
.bounce {
  animation: bounce 0.3s both;
  transform: translate3d(0, 0, 0);
}
@keyframes bounce {
  10%,
  90% {
    transform: scale(1.1);
  }
  20%,
  80% {
    transform: scale(1.15);
  }
  30%,
  70% {
    transform: scale(1.30);
  }
  40%,
  60% {
    transform: scale(1.40);
  }
}
</style>
