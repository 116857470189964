<template>
  <li>
    <div
      class="grid items-center p-2 bg-white bg-opacity-80 mb-1 cursor-pointer"
      style="grid-template-columns: 4rem 4rem 4rem 1fr 4fr 16rem;"
      @click="expanded = !expanded"
    >
      <BaseIcon :icon="'ChevronDown'" class="cursor-pointer w-8 h-8" v-if="expanded"/>
      <BaseIcon :icon="'ChevronRight'" class="cursor-pointer w-8 h-8" v-else />
      <div class="flex items-center pr-2">
        <span class="h-8 w-8">
          <img :src="`${this.$appConfig.apiBaseUrl}${cat.icon}`" />
        </span>
      </div>
      <div class="w-8 h-8" :style="`background-color: ${cat.color};`"/>
      <h3 class="pr-2">
        {{ cat.defaultTranslation.title || $t('Shared.messageNoTranslation') }}
      </h3>
      <p class="pr-2">
        {{ catDescription(cat.defaultTranslation.description, 220) || $t('Shared.messageNoTranslation') }}
      </p>
      <div class="flex justify-end">
        <BaseButton
          :label="$t('Shared.edit') + ' ' + $t('Shared.category')"
          @click="$emit('edit', cat)"
          data-cy="cmsEditCategoryButton"
          class="mr-2"
        />
        <BaseButton
          :label="$t('Shared.add') + ' ' + $t('Shared.question')"
          @click="addQuestion"
          data-cy="cmsAddQuestionButton"
        />
      </div>
    </div>
    <ul v-if="expanded" class="ml-5 flex flex-col relative" data-cy="cmsQuestionsList">
      <CmsQuestionsRow
        v-for="question in cat.questions" :key="`question_${question.id}`"
        :question="question"
        @editQuestion="$emit('editQuestion', question)"
        @addExample="$emit('addExample', question)"
      />
    </ul>
  </li>
</template>

<script>
import BaseIcon from '@/components/shared/baseUi/BaseIcon.vue'
import BaseButton from '@/components/shared/baseUi/BaseButton.vue'
import CmsQuestionsRow from '@/components/cms/CmsQuestionsRow.vue'
import { maxStringLength } from '@/composables/utils'
import { useQuestions } from '@/store/services/questions'
import { useQuestionTranslations } from '@/store/services/question-translations'

export default {
  setup: () => {
    const questionStore = useQuestions()
    const questionTranslationStore = useQuestionTranslations()
    return {
      questionStore,
      questionTranslationStore,
    }
  },
  name: 'CmsCategoriesRow',
  emits: ['edit', 'addQuestion', 'editQuestion'],
  components: {
    BaseIcon,
    BaseButton,
    CmsQuestionsRow,
  },
  props: {
    cat: {
      type: Object,
      required: true,
    },
  },
  data  () {
    return {
      expanded: false,
      refLanguage: 'no_No',
      questions: [],
    }
  },
  methods: {
    addQuestion () {
      this.expanded = false
      this.$emit('addQuestion', this.cat)
    },
    catDescription (string, limit) {
      return maxStringLength(string, limit)
    },
  },
}
</script>

<style scoped>

</style>
