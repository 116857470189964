import useGuestAuth from '@/store/store.guestAuth'
import useAuth from '@/store/store.auth'
import { useProjects } from '@/store/services/projects'
import { useStore } from '@/store/store.pinia'

const project = () => {
  const auth = useAuth()
  const guestAuth = useGuestAuth()
  const store = useStore()

  const createGuestProject = async () => {
    const project = await useProjects().create({
      guestId: guestAuth.guestId,
    })
    return project
  }

  const createUserProject = async () => {
    const project = await useProjects().create({
      userId: auth.userId,
    })
    return project
  }

  const createProject = async () => {
    const project = await useProjects().create({
      userId: auth.isAuthenticated ? auth.userId : null,
      guestId: guestAuth.isAuthenticated ? guestAuth.guestId : null,
    })
    store.setCurrentProjectId(project.id)
    return project
  }

  const updateProjectTitle = async (projectId, title) => {
    const project = await useProjects().patch(projectId, {
      title: title && title.length > 0 ? title : null,
    })
    return project
  }

  return { createProject, createGuestProject, createUserProject, updateProjectTitle }
}

export default project
