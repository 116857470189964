<template>
  <div>
    <figure class="relative cursor-pointer" @click="playPause">
      <div id="video-controls" class="absolute" v-if="!videoIsPlaying">
        <div id="playpause">
          <span class="text-7xl">▶</span>
        </div>
      </div>
      <video
        id="video"
        preload="metadata"
        playsinline
        controls="true"
        class="bg-gray-light mx-auto relative"
      >
        <source :src="url" :type="mimetype">
        Your browser does not support the video tag.
      </video>
    </figure>
  </div>
</template>

<script>
export default {
  name: 'Movie',
  data: () => ({
    videoIsPlaying: false,
    ready: false,
  }),
  props: {
    url: {
      type: String,
      required: true,
    },
    mimetype: {
      type: String,
      required: true,
      default: 'video/mp4',
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      await this.getVideoReady()
      await this.skip(15)
    },
    skip (s) {
      const video = document.getElementById('video')
      video.currentTime = s
    },
    async getVideoReady () {
      const supportsVideo = !!document.createElement('video').canPlayType
      if (supportsVideo) {
        const videoControls = document.getElementById('video-controls')
        document.getElementById('video').controls = false
        videoControls.style.display = 'block'
      }
    },
    playPause () {
      const video = document.getElementById('video')
      if (video.paused || video.ended) {
        video.play()
        this.videoIsPlaying = true
      } else {
        video.pause()
        this.videoIsPlaying = false
      }
    },
  },
}
</script>

<style>
#video-controls {
  left: 50%;
  top: 50%;
  z-index: 2;
  width: 75px;
  height: 75px;
  /* border: #444 4px solid;
  border-radius: 50%; */
  transform: translate(-50%, -50%);
}
#video-controls #playpause {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-left: 5px;
  color: #444;
  }
</style>
