<template>
  <BaseFormModal
    :modelValue="modelValue"
    @update:modelValue="updateModelValue"
    @data-updated="$emit('data-updated')"
    :modalTitle="$t('Cms.Shared.Edit principle')"
    size="lg"
    :form-fields="formFields"
    @confirm="saveCategory"
  />
</template>

<script>
import BaseFormModal from '@/components/shared/baseUi/BaseFormModal'
import { useStore } from '@/store/store.pinia'
import { useCategories } from '@/store/services/categories'
import { useCategoryTranslations } from '@/store/services/category-translations'

export default {
  components: {
    BaseFormModal,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:modelValue', 'data-updated'],
  data () {
    return {
      saving: false,
    }
  },
  setup () {
    const store = useStore()
    const categoryStore = useCategories()
    const categoryTranslationStore = useCategoryTranslations()
    return {
      store,
      categoryStore,
      categoryTranslationStore,
    }
  },
  computed: {
    formFields () {
      return [
        { type: 'hidden', name: 'id', value: this.category.id },
        { type: 'hidden', name: 'categoryTranslationId', value: this.category.defaultTranslation.id },
        { type: 'textInput', name: 'title', label: 'title', value: this.category.defaultTranslation.title, placeholder: 'titel' },
        { type: 'textArea', name: 'description', label: 'beskrivelse', value: this.category.defaultTranslation.description, placeholder: 'beskrivelse' },
        { type: 'color', name: 'color', label: 'color', value: this.category.color, placeholder: 'color' },
        { type: 'icon', name: 'icon', label: 'icon', value: this.category.icon, placeholder: 'icon' },
        { type: 'media', name: 'media', label: 'media', value: this.category.defaultTranslation.category_translation_media, placeholder: 'media' },
      ]
    },
  },
  methods: {
    updateModelValue (val) {
      this.$emit('update:modelValue', val)
    },
    closeModal (close) {
      this.$emit('update:modelValue', false)
    },
    async saveCategory (val) {
      this.saving = true
      // Update translation data
      await this.categoryTranslationStore.patch(val.categoryTranslationId, {
        title: val.title,
        description: val.description,
      })
      // Update category data
      await this.categoryStore.patch(val.id, {
        color: val.color,
      })
      this.$emit('data-updated')
      this.saving = false
      this.closeModal()
    },
  },
  watch: {
    modelValue (newValue) {
      if (newValue) {
        this.saving = false
      }
    },
  },
  mounted () {},
}
</script>

<style scoped>
button:not(:last-child) {
  margin-right: 1rem;
}
</style>
