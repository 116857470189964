<template>
  <div class="absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center">
    <div id="html-spinner"></div>
    <!-- <img :src="spinnerUrl" alt=""> -->
  </div>
</template>

<script>
import spinner from '@/assets/spinner-ios-svgrepo-com.svg'
export default {
  data () {
    return {
      spinnerUrl: spinner,
    }
  },
}
</script>

<style scoped>
#html-spinner{
  width:40px;
  height:40px;
  border:4px solid #00534C;
  border-top:4px solid white;
  border-radius:50%;
}

#html-spinner, #svg-spinner{
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 1.2s;
  -webkit-animation-name: rotate;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-transition-property: -moz-transform;
  -moz-animation-name: rotate;
  -moz-animation-duration: 1.2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  transition-property: transform;
  transition-duration: 1.2s;
  animation-name: rotate;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-webkit-keyframes rotate {
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(360deg);}
}

@-moz-keyframes rotate {
    from {-moz-transform: rotate(0deg);}
    to {-moz-transform: rotate(360deg);}
}

@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}
</style>
