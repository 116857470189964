import feathers from '@feathersjs/feathers'
import auth from '@feathersjs/authentication-client'
import { iff, discard } from 'feathers-hooks-common'
import rest from '@feathersjs/rest-client'
import { config } from '@/config'

class UserAuthenticationClient extends auth.AuthenticationClient {
  authenticate (authentication, params) {
    if (authentication.strategy === 'guest') {
      this.options = {
        ...this.options,
        storageKey: 'guest-jwt',
        header: 'Authorization-Guest',
        jwtStrategy: 'guest',
      }
    } else if (authentication.strategy === 'jwt') {
      this.options = {
        ...this.options,
        storageKey: 'user-jwt',
        header: 'Authorization',
        jwtStrategy: 'jwt',
      }
    } else if (authentication.strategy === 'local') {
      this.options = {
        ...this.options,
        storageKey: 'user-jwt',
        header: 'Authorization',
        jwtStrategy: 'jwt',
      }
    }
    return super.authenticate(authentication, params)
  }
}

const restClient = rest(config.apiBaseUrl)

const headers = () => {
  const x = {}
  if (localStorage.getItem('user-jwt')) {
    x.Authorization = `Bearer ${localStorage.getItem('user-jwt')}`
  } else if (localStorage.getItem('guest-jwt')) {
    x['Authorization-Guest'] = `Bearer ${localStorage.getItem('guest-jwt')}`
  }
  return x
}

const jwtStrategy = () => {
  if (localStorage.getItem('user-jwt')) {
    return 'jwt'
  } else if (localStorage.getItem('guest-jwt')) {
    return 'guest'
  }
  return 'jwt'
}

export const api = feathers()
  .configure(restClient.fetch(window.fetch, {
    headers: headers(),
  }))
  .configure(auth({
    Authentication: UserAuthenticationClient,
    storage: window.localStorage,
    storageKey: 'user-jwt',
    jwtStrategy: jwtStrategy(),
    strategy: jwtStrategy(),
  }))
  .hooks({
    before: {
      all: [
        iff(
          context => ['create', 'update', 'patch'].includes(context.method),
          discard('__id', '__isTemp'),
        ),
      ],
    },
  })
