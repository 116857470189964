<template>
  <button
    class="uppercase"
    :class="buttonStyles"
  >
    <div
      v-if="icon"
      :class="{
      'h-4': size === 'sm',
      'h-6': size === 'md',
      'h-8': size === 'lg',
      'h-10': size === 'xl',
    }"
    >
      <BaseIcon
        :icon="icon"
        :size="size"
        :active="active"
        :key="icon + size + active + label"
      />
    </div>
    <div v-if="layout !== 'icon-only'" class="label">
      {{ label }}
      <slot />
    </div>
  </button>
</template>

<script>
// button layouts:
// normal - rounded, border, text
// with icon - circle around icon text to the right of icon
// icon only - circle around icon

// button types
// normal
// primary
// toggle - class toggles on off on change of prop active

import BaseIcon from '@/components/shared/baseUi/BaseIcon'
export default {
  name: 'BaseButton',
  components: {
    BaseIcon,
  },
  props: {
    layout: {
      type: String,
      default: 'normal', // 'icon-only' || 'icon-and-text'
    },
    type: {
      type: String,
      default: 'outline', // 'filled' || 'emtpy'
    },
    dark: {
      type: Boolean,
      default: false,
    },
    noBg: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    toggle: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    icon: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    buttonStyles () {
      let bgColor = ''
      let textColor = ''
      let hoverBg = ''
      let hoverText = ''
      let textSize = ''
      let layoutStyle = ''
      let shadow = 'shadow'
      if (this.primary) {
        bgColor = this.dark ? 'white' : 'primary'
        textColor = this.dark ? 'primary' : 'white'
        hoverBg = this.dark ? 'secondary' : 'secondary'
        hoverText = this.dark ? 'white' : 'white'
      } else {
        bgColor = 'white'
        textColor = this.dark ? 'white' : 'black'
        hoverBg = this.dark ? 'white' : 'secondary'
        hoverText = this.dark ? 'secondary' : 'white'
      }
      if (this.noBg) {
        bgColor = 'transparent'
        shadow = 'shadow-none'
      }
      let px = 2
      const py = 2
      if (this.size === 'sm') {
        textSize = 'xs'
      }
      if (this.size === 'md') {
        textSize = 'base'
      } else if (this.size === 'lg') {
        textSize = 'lg'
      }
      if (this.layout === 'normal') {
        px += 2
      } else if (this.layout === 'icon-only') {
        // no ekstra style yet
      } else {
        layoutStyle = 'inline-flex justify-center space-x-4'
      }
      return `bg-${bgColor} text-${textColor} hover:bg-${hoverBg} hover:text-${hoverText} text-${textSize} ${shadow} px-${px} py-${py} ${layoutStyle}`
    },
  },
}
</script>

<style scoped>
</style>
