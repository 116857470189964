import { defineStore, BaseModel } from '../store.pinia'
import { api } from '../../feathers'

export class Guest extends BaseModel {
  static instanceDefaults (data) {
    return {
      uuid: '',
    }
  }
}

const servicePath = 'guests'
export const useGuests = defineStore({ servicePath, Model: Guest })

api.service(servicePath).hooks({})
