import { defineStore, BaseModel } from '../store.pinia'
import { api } from '../../feathers'
import { Media } from './media'

export class CategoryTranslationMedia extends BaseModel {
  static instanceDefaults () {
    return {
      categoryTranslationId: null,
      mediaId: null,
    }
  }

  static setupInstance (data, { models }) {
    if (data.media) {
      data.media = new Media(data.media)
    }
    return data
  }
}

const servicePath = 'category-translation-media'
export const useCategoryTranslationMedia = defineStore({ servicePath, Model: CategoryTranslationMedia })

api.service(servicePath).hooks({})
