import { defineStore, BaseModel } from '../store.pinia'
import { api } from '../../feathers'

export class DataExport extends BaseModel {
  static instanceDefaults () {
    return {
      type: null,
    }
  }

  static setupInstance (data, { models }) {
    if (data.translations) {
      data.translations = data.translations.map(item => new models.api.DataExportTranslation(item))
    }
    return data
  }
}

const servicePath = 'data-exports'
export const useDataExports = defineStore({ servicePath, Model: DataExport })

api.service(servicePath).hooks({})
