<template>
  <li class="p-2 mb-1 bg-white bg-opacity-80" :class="editing ? 'bg-green-light' : 'bg-gray-100'">
    <div class="flex space-x-4 items-center">
      <BaseTextbox
        v-if="editing"
        v-model="text"
        :placeholder="'your text'"
        :readonly="!editing"
        :class="editing ? 'bg-white' : ''"
        :minRows="1"
      />
      <span v-else class="flex-grow" @dblclick="editing = true">
        {{ modelValue || $t('Shared.messageNoTranslation') }}
      </span>
      <span class="flex items-center space-x-2">
        <BaseButton
          v-if="!editing"
          :label="$t('Shared.edit')"
          @click="editing = true"/>
        <BaseButton v-if="editing" :label="'cancel'" @click="cancel"/>
        <BaseButton v-if="editing" primary :label="'save'" @click="save"/>
      </span>
    </div>
  </li>
</template>

<script>
import BaseTextbox from '@/components/shared/baseUi/BaseTextbox.vue'
import BaseButton from '@/components/shared/baseUi/BaseButton.vue'

export default {
  name: 'CmsReportTranslationsRow',
  components: { BaseTextbox, BaseButton },
  props: {
    refTranslation: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue', 'cancel', 'save'],
  data () {
    return {
      editing: false,
      text: '',
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler (newValue, oldValue) {
        this.text = newValue
      },
    },
  },
  methods: {
    save () {
      this.$emit('save', this.text)
      this.editing = false
    },
    cancel () {
      this.$emit('cancel')
      this.editing = false
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
