<template>
  <Teleport to="body">
    <vue-final-modal
      v-bind="$attrs"
      classes="modal-container"
      :content-class="`modal-content ${dynClass}`"
      :modelValue="modelValue"
      @update:modelValue="updateModelValue"
    >
      <span class="modal__title"
        :class="size === 'full' ? 'text-black' : ''"
      >
        <slot name="title">{{ modalTitle }}</slot>
      </span>
      <div class="modal__content">
        <div v-if="formState === 'loading'" class="relative h-20">
          <BaseLoader />
        </div>
        <div v-if="formState === 'succesfull'" class="text-4xl w-full h-full flex justify-center items-center">
          {{ succesMessage }}
          <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
          </svg>
        </div>
        <template v-if="fields.id && formState === 'normal'">
          <div class="space-y-4">
            <div v-for="field in formFields" :key="field.name">
              <label v-if="field.type !== 'hidden'">
                <div class="text-xs uppercase">{{ field.label }}</div>
                <BaseTextbox
                  v-if="field.type === 'textInput' || field.type === 'textArea'"
                  class="bg-gray-light"
                  v-model="fields[field.name]"
                  :placeholder="field.placeholder"
                  :name="field.name"
                  :minRows="field.type === 'textInput' ? 1 : 3"
                />
                <BaseTextbox
                  v-if="field.type === 'number'"
                  class="bg-gray-light"
                  v-model="fields[field.name]"
                  :placeholder="field.placeholder"
                  :name="field.name"
                  :minRows="field.type === 'textInput' ? 1 : 3"
                  value-type="number"
                />
                <div v-else-if="field.type === 'color'" class="flex">
                  <div class="w-8 h-8 mr-5 inline-block" :style="`background-color: ${fields[field.name]};`" />
                  <div class="inline-block">
                    <BaseTextbox
                      class="bg-gray-light"
                      v-model="fields[field.name]"
                      :placeholder="field.placeholder"
                      :name="field.name"
                      :minRows="1"
                    />
                  </div>
                </div>
                <div v-else-if="field.type === 'icon'" class="h-8 w-8">
                  <img :src="`${this.$appConfig.apiBaseUrl}${fields[field.name]}`" />
                </div>
                <div v-else-if="field.type === 'select'" class="flex">
                  <select v-model="fields[field.name]">
                    <option v-for="(option, idx) in field.options" :value="option.id" :key="idx">
                      {{ option.defaultTranslation.title }}
                    </option>
                  </select>
                </div>
                <div v-else-if="field.type === 'checkbox'" class="flex">
                  <label>
                    <input type="checkbox" required v-model="fields[field.name]" />
                    <span class="ml-2">{{ field.name }}</span>
                  </label>
                </div>
                <div v-else-if="field.type === 'media'" class="flex">
                  <div v-for="(translationMedia, idx) in fields.media" :key="idx" class="w-80 h-80 mr-5 inline-block" >
                    <div>
                      <Movie
                        v-if="translationMedia.media.type == 'video'"
                        :url="`${this.$appConfig.apiBaseUrl}${translationMedia.media.location}`"
                        :mimetype="translationMedia.media.mimetype"
                      />
                      <img
                        v-else-if="translationMedia.media.type == 'image'"
                        :src="`${this.$appConfig.apiBaseUrl}${translationMedia.media.location}`"
                        class="border-black border-2"
                      />
                      <div class="flex mt-3">
                        <div v-if="files.length > 0" class="mr-5">
                          <ul>
                            <li v-for="file in files" :key="file.name">
                              <img :src="file.blob" width="50" height="50" />
                              {{file.name}}
                            </li>
                          </ul>
                        </div>
                        <file-upload
                          ref="upload"
                          v-model="files"
                          :post-action="`${this.$appConfig.apiBaseUrl}/upload`"
                          :data="{ categoryId: fields.id, mediaId: translationMedia.mediaId }"
                          :headers="fileHeaders"
                          @input-file="inputFile"
                          @input-filter="inputFilter"
                        >
                          <span>Last opp fil</span>
                        </file-upload>
                      </div>
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </template>
      </div>
      <div class="modal__action" v-if="formState === 'normal'">
        <slot name="actions">
          <BaseButton @click="$emit('update:modelValue', false)">Annullér</BaseButton>
          <BaseButton primary @click="confirm">{{ confirmLabel }}</BaseButton>
        </slot>
      </div>
      <BaseButton
        v-if="formState === 'normal'"
        :layout="'icon-only'"
        :icon="'Close'"
        class="modal__close"
        :class="size === 'full' ? 'text-black' : ''"
        @click="close"
      ></BaseButton>
    </vue-final-modal>
  </Teleport>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import BaseButton from '@/components/shared/baseUi/BaseButton'
import BaseLoader from '@/components/shared/baseUi/BaseLoader.vue'
import BaseTextbox from '@/components/shared/baseUi/BaseTextbox.vue'
import VueUploadComponent from 'vue-upload-component'
import Movie from '@/components/shared/media/Movie.vue'

export default {
  name: 'VModal',
  inheritAttrs: false,
  emits: ['update:modelValue', 'confirm', 'data-updated'],
  components: {
    BaseButton,
    BaseLoader,
    VueFinalModal,
    BaseTextbox,
    FileUpload: VueUploadComponent,
    Movie,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    formFields: {
      type: Array,
      required: true,
    },
    formState: {
      type: String, // loading || normal || saved || error
      default: 'normal',
    },
    modalTitle: {
      type: String,
      default: 'Titel',
    },
    confirmLabel: {
      type: String,
      default: 'ok',
    },
    succesMessage: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'md',
    },
  },
  data () {
    return {
      fields: {},
      files: [],
      shouldUpload: false,
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.formFields.forEach(field => {
            this.fields[field.name] = field.value
          })
        }
        console.log(this.formFields)
      },
    },
    files (newVal) {
      this.shouldUpload = true
    },
    formFields (newVal) {
      this.fields = []
      this.formFields.forEach(field => {
        this.fields[field.name] = field.value
      })
    },
  },
  computed: {
    dynClass () {
      if (this.size === 'md') return 'bg-white min-w-90 md:min-w-1/2 lg:min-w-1/4'
      if (this.size === 'full') return 'bg-white min-w-full min-h-full'
      if (this.size === 'lg') return 'bg-white min-w-3/4'
      else return ''
    },
    fileHeaders () {
      const headers = {}
      if (localStorage.getItem('user-jwt')) {
        headers.Authorization = `Bearer ${localStorage.getItem('user-jwt')}`
      }
      return headers
    },
  },
  methods: {
    updateModelValue (val) {
      this.$emit('update:modelValue', val)
    },
    close () {
      this.$emit('update:modelValue', false)
    },
    async confirm () {
      if (this.shouldUpload) {
        this.$refs.upload[0].active = true
      } else {
        this.$emit('confirm', this.fields)
      }
    },
    /**
     * Has changed
     * @param  Object|undefined   newFile   Read only
     * @param  Object|undefined   oldFile   Read only
     * @return undefined
     */
    inputFile: function (newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        // Get response data
        console.log('response', newFile.response)
        this.$emit('confirm', this.fields)
        this.$emit('data-updated')
      }
    },
    /**
     * Pretreatment
     * @param  Object|undefined   newFile   Read and write
     * @param  Object|undefined   oldFile   Read only
     * @param  Function           prevent   Prevent changing
     * @return undefined
     */
    inputFilter: function (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(jpeg|jpe|jpg|gif|png|webp|mp4|mov)$/i.test(newFile.name)) {
          return prevent()
        }
      }

      const URL = window.URL || window.webkitURL
      newFile.blob = ''
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file)
      }
    },
  },
}
</script>

<style scoped>
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;
  padding: 1rem;
  /* border: 1px solid #e2e8f0; */
  border-radius: 0.25rem;
  /* background: #fff; */
  max-width: 30%;
}
.modal__title {
  margin: 0 3rem .5rem 0;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 400;
}
.modal__content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem 0;
}
.modal__content > * {
  display: block;
  margin-bottom: 1rem;
}
.modal__action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem 0 0;
}
.modal__action button {
  margin-bottom: 0;
}
.modal__action > *:not(:last-child) {
  margin-right: 1rem;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.dark-mode div ::v-deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 0 0 0 1rem;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
.file-uploads {
  overflow: hidden;
  position: relative;
  text-align: center;
  display: inline-block;
}
.file-uploads.file-uploads-html4 input[type="file"] {
  opacity: 0;
  font-size: 20em;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}
.file-uploads.file-uploads-html5 input[type="file"] {
  overflow: hidden;
  position: fixed;
  width: 1px;
  height: 1px;
  z-index: -1;
  opacity: 0;
}
</style>
