<template>
  <li class="mb-2 py-1" :class="editing ? 'bg-green-light' : 'bg-gray-100'">
    <span class="col-span-6 ml-8 flex items-center">{{ name }}</span>
    <div class="col-span-6 flex space-x-4 items-center">
      <BaseTextbox
        v-if="editing"
        :modelValue="modelValue"
        @update:modelValue="updateText"
        :placeholder="'your text'"
        :readonly="!editing"
        :class="editing ? 'bg-white' : ''"
        :minRows="1"
        @keydown.enter="save"
        @keydown.esc="cancel"
      />
      <span v-else class="flex-grow" @dblclick="editing = true">
        <span v-if="$t(i18nKey, i18nLocale + '!')">{{ $t(i18nKey, i18nLocale + '!') }}</span>
        <span v-else class="italic">{{ $t('Cms.Shared.MessageNoTranslation') }}</span>
      </span>
      <span class="flex items-center space-x-2">
        <BaseButton
          v-if="!editing"
          :label="$t('Shared.edit')"
          :size="'sm'"
          @click="editing = true"
        />
        <BaseButton
          v-if="editing"
          :label="$t('Shared.cancel')"
          @click="cancel"
          :size="'sm'"
        />
        <BaseButton
          v-if="editing"
          primary
          :label="$t('Shared.save')"
          @click="save"
          :size="'sm'"
        />
      </span>
    </div>
  </li>
</template>

<script>
import BaseTextbox from '@/components/shared/baseUi/BaseTextbox.vue'
import BaseButton from '@/components/shared/baseUi/BaseButton.vue'

export default {
  components: { BaseTextbox, BaseButton },
  props: {
    name: {
      type: String,
      required: true,
    },
    i18nKey: {
      type: String,
      required: true,
    },
    i18nLocale: {
      type: String,
      required: true,
    },
    i18nRefLocale: {
      type: String,
      default: 'en',
    },
    modelValue: {
      type: String,
      required: true,
    },
    currentlyEditing: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue', 'cancel', 'save', 'update:currentlyEditing'],
  data () {
    return {
      editing: false,
      value: null,
    }
  },
  watch: {
    editing (value) {
      if (value) this.$emit('update:currentlyEditing', this.i18nKey)
      else this.$emit('update:currentlyEditing', '')
    },
    modelValue: {
      immediate: true,
      deep: true,
      handler (newValue, oldValue) {
        this.value = newValue
      },
    },
  },
  methods: {
    updateText (val) {
      this.value = val
      // this.$emit('update:modelValue', val)
    },
    save () {
      this.$emit('save', this.value)
      this.editing = false
    },
    cancel () {
      this.value = this.modelValue
      this.editing = false
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
