const config = {
  frontendUrl: parse(process.env.VUE_APP_FRONTEND_URL, 'http://localhost:8080'),
  apiBaseUrl: parse(process.env.VUE_APP_API, 'http://localhost:3030'),
  features: {},
}
function feature (name) {
  return config.features[name]
}
function parse (value, fallback) {
  if (typeof value === 'undefined') {
    return fallback
  }
  switch (typeof fallback) {
    case 'boolean' :
      return !!JSON.parse(value)
    case 'number' :
      return JSON.parse(value)
    default :
      return value
  }
}
export {
  config,
}
export default {
  install: (app, options) => {
    app.config.globalProperties.$appConfig = config
    app.config.globalProperties.$feature = (name) => {
      return feature(name)
    }
  },
}
