<template>
  <BaseFormModal
    v-if="ready"
    :modelValue="modelValue"
    @update:modelValue="updateModelValue"
    @data-updated="$emit('data-updated')"
    modalTitle="Ret page"
    size="lg"
    :form-fields="formFields"
    @confirm="saveContent"
  />
</template>

<script>
import BaseFormModal from '@/components/shared/baseUi/BaseFormModal'
import { useContent } from '@/store/services/content'
import { useContentTranslations } from '@/store/services/content-translations'

export default {
  components: {
    BaseFormModal,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    content: {
      type: Array,
      required: true,
    },
    pageName: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue', 'data-updated'],
  data () {
    return {
      saving: false,
      formFields: [{ type: 'hidden', name: 'id', value: 1 }],
      ready: false,
    }
  },
  setup () {
    const contentStore = useContent()
    const contentTranslationStore = useContentTranslations()
    return {
      contentStore,
      contentTranslationStore,
    }
  },
  async mounted () {
    for await (const [key, field] of Object.entries(this.content)) {
      let type = ''
      let value = ''
      if (field.contentType === 'text') {
        type = 'textArea'
        value = field.defaultTranslation.value
      } else if (field.contentType === 'media') {
        type = 'media'
        value = field.defaultTranslation.content_translation_media
      }
      this.formFields.push({
        type: type,
        name: key,
        label: type,
        value: value,
        placeholder: type,
      })
    }
    this.formFields.push({ type: 'hidden', name: 'page', value: this.pageName })
    this.ready = true
  },
  methods: {
    updateModelValue (val) {
      this.$emit('update:modelValue', val)
    },
    closeModal (close) {
      this.$emit('update:modelValue', false)
    },
    async saveContent (val) {
      this.saving = true
      await this.contentStore.create({
        page: val.page,
        items: val,
      })
      this.$emit('data-updated')
      this.saving = false
      this.closeModal()
    },
  },
  watch: {
    modelValue (newValue) {
      if (newValue) {
        this.saving = false
      }
    },
  },
}
</script>

<style scoped>
button:not(:last-child) {
  margin-right: 1rem;
}
</style>
