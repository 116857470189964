<template>
  <BasePageLayout :loading="loading">
    <div class="flex justify-between items-start">
      <h1 class="text-3xl mb-6">{{ $t('Projects.Project list view.Title')}}</h1>
      <BaseButton
        :label="$t('Projects.Project list view.ButtonNewProject')"
        @click="$router.push('/survey')"
        data-cy="newProjectButton"
      />
    </div>
    <div v-if="projects.length === 0">
      {{ $t('Projects.Project list view.MessageNoProjects')}}
    </div>
    <ul v-else class="grid grid-cols-3 gap-12">
      <li
        v-for="project in projects" :key="project.id"
        class="cursor-pointer rounded-xl shadow hover:shadow-xl transition-all hover:-translate-y-2 p-6 bg-white bg-opacity-80 relative"
      >
        <h2 class="text-xl">{{ project.title ?? $t('Projects.Project list view.No name') }}</h2>
        <small class="text-gray-dark">{{ $dayjs(project.updatedAt).format('DD.MM.YYYY HH:mm') }}</small>
        <roseChart
          :chartId="`projectlist_chart_${project.id}`"
          :projectCategories="project.project_categories"
          :showLabels="false"
          :clickable="false"
        />
        <div
          class="absolute top-0 right-0 bottom-0 left-0"
          @click="$router.push({ name: 'project', params: { projectId: project.id } })"
        >
        </div>
      </li>
    </ul>
  </BasePageLayout>
</template>

<script>
// import findUserProjects from '@/api/findUserProjects'
import roseChart from '@/components/shared/RoseChart.vue'
import BasePageLayout from '@/components/shared/baseUi/BasePageLayout.vue'
import BaseButton from '@/components/shared/baseUi/BaseButton.vue'
import { useProjects } from '@/store/services/projects'

export default {
  components: { roseChart, BasePageLayout, BaseButton },
  name: 'ProjectsView',
  setup () {
    const projectStore = useProjects()
    return {
      projectStore,
    }
  },
  data () {
    return {
      projects: [],
      loading: false,
    }
  },
  async mounted () {
    this.loading = true
    await this.getProjects()
    this.loading = false
  },
  methods: {
    async getProjects () {
      this.projects = await this.projectStore.find({
        query: {
          populate: true,
        },
      })
    },
  },
}
</script>

<style>

</style>
