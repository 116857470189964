<template>
  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md" :class="{'bg-gray-light': isEmbedded}">
    <div class="py-8 px-4 shadow sm:px-10" :class="{'px-2 sm:px-2': isEmbedded, 'bg-white': !isEmbedded}">
      <div v-if="errorMessage" class="text-red mb-4">{{ errorMessage }}</div>
      <form class="space-y-6" action="#" method="POST" @submit.prevent="submit">
        <div>
          <label v-if="!isEmbedded" for="name" class="block text-sm font-medium text-gray-700 capitalize">
            {{ $t('Users.Signup view.Label01') }}
          </label>
          <div class="mt-1">
            <input
              v-model="newUser.name"
              id="name"
              name="name"
              type="name"
              autocomplete="name"
              :placeholder="!isEmbedded ? $t('Users.Signup view.Input01_placeholder') : $t('Users.Signup view.Label01')"
              required minlength="2"
              class="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
          </div>
        </div>

        <div>
          <label v-if="!isEmbedded" for="email" class="block text-sm font-medium text-gray-700 capitalize">
            {{ $t('Users.Signup view.Label02') }}
          </label>
          <div class="mt-1">
            <input
              @keyup="errorMessage = null"
              v-model="newUser.email"
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              :placeholder="$t('Users.Signup view.Input02_placeholder')"
              required class="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div>
          <label v-if="!isEmbedded" for="password" class="block text-sm font-medium text-gray-700 capitalize">
            {{ $t('Users.Signup view.Label03') }}
          </label>
          <div class="mt-1">
            <input
              v-model="newUser.password"
              id="password"
              name="password"
              type="password"
              autocomplete="current-password"
              required minlength="6"
              :placeholder="$t('Users.Signup view.Input03_placeholder')"
              class="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div>
          <label>
            <input type="checkbox" required v-model="hasAgreed" />
            <span class="ml-2 text-sm">{{$t('Users.Signup view.Agreement01')}} <a target="_blank" :href="$t('Users.Signup view.Agreement03')">{{$t('Users.Signup view.Agreement02')}}</a></span>
          </label>
        </div>

        <div v-if="!isEmbedded">
          <button type="submit" class="bg-primary text-white w-full flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium bg-gray-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 capitalize">
            {{ $t('Users.Signup view.Button01') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import useGuestAuth from '@/store/store.guestAuth'
import useAuth from '@/store/store.auth'
import { useUsers } from '@/store/services/users'
import { useStore } from '@/store/store.pinia'

export default {
  data: () => ({
    newUser: null,
    errorMessage: null,
    hasAgreed: false,
  }),
  props: {
    isEmbedded: {
      type: Boolean,
      default: false,
    },
    submitted: {
      type: Boolean,
      default: false,
    },
    projectTitle: {
      type: String,
      default: null,
    },
    projectId: {
      type: Number,
      default: null,
    },
  },
  created () {
    const UserModel = useUsers().Model
    this.newUser = new UserModel()
  },
  setup () {
    const guestAuth = useGuestAuth()
    const auth = useAuth()
    const store = useStore()
    return {
      guestAuth,
      auth,
      store,
    }
  },
  computed: {
    guest () {
      return this.guestAuth.guest
    },
  },
  watch: {
    submitted (val, prevVal) {
      if (val !== prevVal) {
        this.submit()
      }
    },
  },
  methods: {
    async submit () {
      if (!this.hasAgreed) {
        this.errorMessage = this.$t('Users.Signup view.ErrorMessage03')
        return
      }
      try {
        if (this.guest) {
          this.newUser.guestId = this.guest.id
          this.newUser.projectTitle = this.projectTitle
          this.newUser.projectId = this.projectId
        }
        const _createdUser = await this.newUser.create()
        if (_createdUser.id) {
          this.guestAuth.logout()
          this.store.resetCurrentProject()
          await this.autoLogin()
          this.$router.push('/projects')
        }
      } catch (e) {
        if (e.code === 405) {
          this.errorMessage = this.$t('Users.Signup view.ErrorMessage01')
        } else {
          this.errorMessage = this.$t('Users.Signup view.ErrorMessage02')
        }
        console.log(e)
      }
    },
    async autoLogin () {
      await this.auth.authenticate({
        strategy: 'local',
        email: this.newUser.email,
        password: this.newUser.password,
      })
    },
  },
}
</script>

<style>

</style>
