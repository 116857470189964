<template>
  <div class="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="mt-6 text-center text-3xl font-extrabold">
        <span>{{ $t('Users.Signup view.Title01') }}</span>
      </h2>
    </div>
    <Create />
  </div>
</template>

<script>
import Create from '@/components/user/Create'

export default {
  components: {
    Create,
  },
}
</script>

<style>

</style>
