<template>
  <ul>
    <li class="py-4 grid grid-cols-12">
      <p class="col-span-12 lg:col-span-7 xl:col-span-8 text-xl">{{$t('Survey.Questions["How much will you work for..."]')}}</p>
      <div class="col-span-12 lg:col-span-5 xl:col-span-4 hidden lg:grid grid-cols-5 text-2xs">
        <span
          v-for="label in labels" :key="label"
          class="px-1 text-center"
        >{{ label }}</span>
      </div>
    </li>
    <slot/>
  </ul>
</template>

<script>
export default {
  name: 'QuestionsList',
  data () {
    return {
      labels: ['I svært liten grad', 'I liten grad', 'I noen grad', 'I høy grad', 'I svært høy grad'],
    }
  },
}
</script>

<style>

</style>
