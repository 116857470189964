<template>
  <div>
    <ul class="mb-2 mt-4">
      <li class="grid grid-cols-2 font-bold">
        <h3 class="flex items-center">{{ $t('Shared.text') }}</h3>
      </li>
    </ul>
    <CmsReportTranslationsRow
      class="grid grid-cols-1 custom-divide"
      v-for="translationObj in reportTranslationTexts" :key="translationObj.id"
      :refTranslation="translationObj.defaultTranslation ? translationObj.defaultTranslation.text : ''"
      :modelValue="translationObj.defaultTranslation ? translationObj.defaultTranslation.text : ''"
      @cancel="cancelEdit"
      @save="saveEdit($event, translationObj, editLanguage)"
    />
  </div>
</template>

<script>
import CmsReportTranslationsRow from '@/components/cms/CmsReportTranslationsRow.vue'
import { useDataExports } from '@/store/services/data-exports'
import { useDataExportTranslations } from '@/store/services/data-export-translations'
import languages from '@/i18n/languages'

export default {
  components: {
    CmsReportTranslationsRow,
  },
  props: {
    locale: {
      type: String,
      default: 'no_NO',
    },
  },
  setup () {
    const dataExportStore = useDataExports()
    const dataExportTranslationStore = useDataExportTranslations()
    return {
      dataExportStore,
      dataExportTranslationStore,
    }
  },
  data () {
    return {
      languages: languages,
      refLanguage: 'no_NO',
      reportTranslationTexts: [],
      editLanguage: 'no_NO',
    }
  },
  mounted () {
    this.getTranslations()
  },
  methods: {
    async getTranslations () {
      this.reportTranslationTexts = await this.dataExportStore.find({
        query: {},
      })
    },
    cancelEdit () {
      //
    },
    async saveEdit (value, translationObj, editLanguage) {
      console.log(value, translationObj, editLanguage)
      await this.dataExportTranslationStore.patch(translationObj.defaultTranslation.id, {
        text: value,
      })
      this.getTranslations()
    },
  },
}
</script>

<style scoped>
select {
  border: none !important;
}
</style>
