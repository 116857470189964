import { defineStore, BaseModel } from '../store.pinia'
import { api } from '../../feathers'
import { Category } from './categories'
import { ProjectCategoryAnswer } from './project-category-answers'

export class ProjectCategory extends BaseModel {
  static instanceDefaults () {
    return {
      projectId: null,
      categoryId: null,
      score: 0,
      maxScore: 5,
    }
  }

  static setupInstance (data, { models }) {
    if (data.category) {
      data.category = new Category(data.category)
    }
    if (data.project_category_answer) {
      data.answer = new ProjectCategoryAnswer(data.project_category_answer)
    } else {
      data.answer = new ProjectCategoryAnswer({
        projectCategoryId: data.id,
      })
    }
    return data
  }
}

const servicePath = 'project-categories'
export const useProjectCategories = defineStore({ servicePath, Model: ProjectCategory, pagination: false })

api.service(servicePath).hooks({})
