import { defineStore, BaseModel } from '../store.pinia'
import { api } from '../../feathers'

export class ProjectCategoryAnswer extends BaseModel {
  static instanceDefaults () {
    return {
      value: null,
      userId: null,
      guestId: null,
      projectCategoryId: null,
    }
  }
}

const servicePath = 'project-category-answers'
export const useProjectCategoryAnswers = defineStore({ servicePath, Model: ProjectCategoryAnswer })

api.service(servicePath).hooks({})
