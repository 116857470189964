<template>
  <div class="fade" :class="layoutClass">
    <BaseLoader v-if="loading" />
    <template v-else>
      <slot />
    </template>
  </div>
</template>

<script>
import BaseLoader from '@/components/shared/baseUi/BaseLoader.vue'

export default {
  name: 'BasePageLayout',
  components: { BaseLoader },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    layoutClass: {
      type: String,
      default: 'layout-page',
    },
  },
}
</script>

<style>
.fade {
  -webkit-mask-image: linear-gradient(180deg, transparent 0rem, #000 7rem);
}
</style>
