<template>
  <BasePageLayout class="layout-admin-page">
    <!-- TABS -->
    <section class="">
      <ul v-if="mainContent !== 'Theme'" class="flex space-x-8 text-xl" data-cy="cmsNavigationLinks">
        <li
          class="border-solid border-gray-500 cursor-pointer"
          :class="mainContent === 'text' ? 'border-b-2' : ''"
          @click="mainContent = 'text'"
        >
          {{ $t('Cms.Main view.TabTexts') }}
        </li>
        <li
          class="border-solid border-gray-500 cursor-pointer"
          :class="mainContent === 'categories' ? 'border-b-2' : ''"
          @click="mainContent = 'categories'"
        >
          {{ $t('Cms.Main view.TabPrinciples') }}
        </li>
        <li
          class="border-solid border-gray-500 cursor-pointer"
          :class="mainContent === 'examples' ? 'border-b-2' : ''"
          @click="mainContent = 'examples'"
        >
          {{ $t('Cms.Main view.TabExamples') }}
        </li>
        <li
          class="border-solid border-gray-500 cursor-pointer"
          :class="mainContent === 'pages' ? 'border-b-2' : ''"
          @click="mainContent = 'pages'"
        >
          {{ $t('Cms.Main view.TabPages') }}
        </li>
        <li
          class="border-solid border-gray-500 cursor-pointer"
          :class="mainContent === 'report' ? 'border-b-2' : ''"
          @click="mainContent = 'report'"
        >
          {{ $t('Cms.Main view.TabReport') }}
        </li>
      </ul>
    </section>
    <!-- TEXT SECTION -->
    <section>
      <CmsTranslator v-if="mainContent === 'text'" />
    </section>
    <section>
      <Cmscategories v-if="mainContent === 'categories'" />
    </section>
    <section>
      <CmsPages v-if="mainContent === 'pages'" />
    </section>
    <section>
      <CmsReportTranslations v-if="mainContent === 'report'" />
    </section>
    <section>
      <CmsQuestionExamples v-if="mainContent === 'examples'" />
    </section>
  </BasePageLayout>
</template>

<script>
import CmsTranslator from '@/components/cms/CmsTranslator.vue'
import Cmscategories from '@/components/cms/CmsCategories.vue'
import CmsPages from '@/components/cms/CmsPages.vue'
import CmsReportTranslations from '@/components/cms/CmsReportTranslations.vue'
import CmsQuestionExamples from '@/components/cms/CmsQuestionExamples.vue'
import BasePageLayout from '@/components/shared/baseUi/BasePageLayout.vue'

export default {
  components: { CmsTranslator, BasePageLayout, Cmscategories, CmsPages, CmsReportTranslations, CmsQuestionExamples },
  data () {
    return {
      mainContent: 'text',
    }
  },
}
</script>

<style scoped>

</style>
