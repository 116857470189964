import { defineStore, BaseModel } from '../store.pinia'
import { api } from '../../feathers'
import { CategoryTranslationMedia } from './category-translation-media'

export class CategoryTranslation extends BaseModel {
  static instanceDefaults () {
    return {
      title: null,
      description: null,
      categoryId: null,
      localeId: null,
      locale: null,
    }
  }

  static setupInstance (data, { models }) {
    if (data.category_translation_media) {
      data.category_translation_media = data.category_translation_media.map(item => new CategoryTranslationMedia(item))
    }
    return data
  }
}

const servicePath = 'category-translations'
export const useCategoryTranslations = defineStore({ servicePath, Model: CategoryTranslation })

api.service(servicePath).hooks({})
