<template>
  <div class="flex justify-center md:flex-col md:justify-end gap-4 p-3 md:p-0 bg-gray-light md:bg-transparent">
    <BaseButton class="md:hidden" :label="$t('Shared.close')" @click="$emit('close')" />
    <BaseButton v-if="!first" :label="$t('Shared.previous')" @click="$emit('prev')" />
    <BaseButton v-if="!last" :label="$t('Shared.next')" primary @click="$emit('next')" />
  </div>
</template>

<script>
import BaseButton from '../shared/baseUi/BaseButton.vue'

export default {
  components: { BaseButton },
  name: 'SurveyStepNavigation',
  props: {
    first: {
      type: Boolean,
      required: true,
    },
    last: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style>

</style>
