import useGuestAuth from '@/store/store.guestAuth'
import useAuth from '@/store/store.auth'
import { useGuests } from '@/store/services/guests'
import { useProjects } from '@/store/services/projects'

const guest = () => {
  const auth = useAuth()
  const guestAuth = useGuestAuth()

  const createGuest = async () => {
    console.log(await guestAuth.isAuthenticated)
    if (!auth.isAuthenticated && !guestAuth.isAuthenticated) {
      const Guest = useGuests().Model
      const newGuest = new Guest()
      const g = await newGuest.create()
      await guestAuth.authenticate({
        strategy: 'guest',
        accessToken: g.token,
      })
    }
  }

  const createGuestProject = async () => {
    const project = await useProjects().create({
      guestId: guestAuth.guestId,
    })
    return project
  }

  return { createGuest, createGuestProject }
}

export default guest
