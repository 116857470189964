<template>
  <div class="textbox-container">
    <div :class="`text-${textSize} text-${textColor}`" :ref="ref">{{modelValue}}</div>
    <textarea
      v-if="valueType === 'string'"
      :class="`text-${textSize} text-${textColor} flex-grow`"
      :readonly="readonly"
      :value="modelValue"
      @input="updateValue"
      v-on:keydown="keyPressed"
      v-on:keyup="$emit('keypressed', $event)"
      v-on:focus="updateHeight()"
      v-on="!readonly ? { dblclick: dblclickHandler } : {}"
      :ref="inputref"
      :placeholder="placeholder"
      :name="name"
      >
    </textarea>
    <input v-else-if="valueType === 'number'"
      class="bg-gray-light"
      :class="`text-${textSize} text-${textColor} flex-grow`"
      :value="modelValue"
      @input="updateValue"
      v-on:keydown="keyPressed"
      v-on:keyup="$emit('keypressed', $event)"
      v-on="!readonly ? { dblclick: dblclickHandler } : {}"
      :ref="inputref"
      :placeholder="placeholder"
      :name="name"
      type="number"
    />
  </div>
</template>

<script>
export default {
  name: 'baseTextbox',
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    textSize: {
      type: String,
      required: false,
      default: 'md',
    },
    textColor: {
      type: String,
      required: false,
      default: 'black',
    },
    minRows: {
      type: Number,
      required: false,
      default: 2,
    },
    maxLength: {
      type: Number,
      required: false,
      default: null,
    },
    inputId: {
      type: String,
      default: '',
    },
    enterAdds: {
      type: Boolean,
      default: false,
    },
    valueType: {
      type: String,
      default: 'string',
    },
  },
  emits: ['update:modelValue', 'keypressed', 'inputEnter'],
  data () {
    return {
      ref: null,
      inputref: null,
      textHeight: 0,
    }
  },
  computed: {},
  methods: {
    dblclickHandler (event) {
      event.stopPropagation()
    },
    updateValue (evt) {
      if ((evt.inputType !== 'insertLineBreak') && !((evt.inputType === 'insertText') && (evt.data === null))) {
        this.$emit('update:modelValue', evt.target.value)
      }
      this.updateHeight()
    },
    keyPressed (e) {
      if (this.enterAdds && (e.keyCode === 13) && !e.shiftKey) {
        e.preventDefault()
        this.$emit('inputEnter')
      }
    },
    updateHeight (e) {
      if (this.valueType === 'string') {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop
        this.$refs[this.ref].style.display = 'inline-block'
        this.$refs[this.ref].style.width = this.$refs[this.inputref].scrollWidth + 'px'
        this.$refs[this.inputref].style.height = this.$refs[this.ref].scrollHeight + 'px'
        this.$refs[this.ref].style.display = 'none'
        this.$refs[this.inputref].style.height = this.$refs[this.inputref].scrollHeight + 'px'
        window.scrollTo(0, scrollPosition)
      }
    },
    focusInput () {
      this.$refs[this.inputref].focus()
    },
  },
  created () {
    this.ref = 'md-' + this._uid
    this.inputref = 'inputref'
  },
  mounted () {
    if (this.textSize === 'sm') {
      this.textHeight = 18
    } else if (this.textSize === 'md') {
      this.textHeight = 24
    } else if (this.textSize === 'lg') {
      this.textHeight = 28
    }
    this.$nextTick(() => {
      if (this.valueType === 'string') {
        this.$refs[this.ref].style.display = 'inline-block'
        this.$refs[this.inputref].style.height = (this.minRows * this.textHeight) + 'px'
        this.$refs[this.inputref].style.minHeight = (this.minRows * this.textHeight) + 'px'
        this.$refs[this.ref].style.minHeight = (this.minRows * this.textHeight) + 'px'
        this.$refs[this.ref].style.display = 'none'
        this.$refs[this.inputref].style.height = this.$refs[this.inputref].scrollHeight + 'px'
        this.updateHeight()
        window.addEventListener('resize', this.updateHeight)
      }
    })
  },
  unmounted () {
    window.removeEventListener('resize', this.updateHeight)
  },
  updated () {
    // this.updateHeight()
  },
  watch: {
    editState () {
      this.$nextTick(() => {
        this.updateHeight()
      })
    },
    value () {
    },
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
textarea {
  resize: none;
  margin: 0;
  padding: 0;
  border: none;
}
textarea:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
.textbox-container {
  position: relative;
  display: flex;
  flex: 1;
  padding: 0;
  overflow: auto;
  /* margin: 0; */
  /* font-size: 0; */
}
.textbox-container div {
  display: none;
  box-sizing: border-box;
  position: absolute;
  /* white-space: pre-wrap; */
}
.textbox-container textarea {
  padding: 0;
  box-sizing: border-box;
  background: none;
  outline: none;
  overflow: auto;
}
</style>
