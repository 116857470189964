<template>
  <div v-if="content" class="markdown-content">
    <div class="mb-4" v-html="toHtml(content[0].defaultTranslation.value)"></div>
    <div class="grid grid-cols-1 grid-rows:2 xl:grid-cols-2 xl:grid-rows-1 gap-12">
      <div class="row-start-2 xl:row-start-1">
        <ul class="mb-4">
          <li v-for="(projectCat, idx) in sortedProjectCats" :key="idx">
            <b>{{ projectCat.category.id }}</b> – {{ projectCat.category.defaultTranslation.title }}: <b>{{ parseFloat(projectCat.score).toFixed(1)}}</b>
          </li>
        </ul>
        <div class="mb-4" v-html="toHtml(content[1].defaultTranslation.value)"></div>
        <div class="flex gap-4">
          <BaseButton primary :label="$t('Survey.Download report')" @click="doDownload(`${$appConfig.apiBaseUrl}/data-exports`, project.id, project.title ?? $t('Projects.Project list view.No name'), 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')" />
          <BaseButton :label="$t('Survey.Download CSV file')" @click="doDownload(`${$appConfig.apiBaseUrl}/data-exports`, project.id, project.title ?? $t('Projects.Project list view.No name'), 'text/csv')" />
          <BaseButton v-if="!loggedIn" :label="$t('Survey.Save on Profile')" @click="showSaveModal = true"/>
        </div>
      </div>
      <div class="row-start-1 flex justify-center">
        <RoseChart v-if="projectCategories.length > 0" class="w-1/2 xl:w-full" :chartId="'123'" :projectCategories="projectCategories" />
      </div>
    </div>
    <ModalSaveToProfile
      v-if="project"
      v-model="showSaveModal"
      :project-id="project.id"
    />
  </div>
</template>

<script>
import RoseChart from '@/components/shared/RoseChart.vue'
import BaseButton from '../shared/baseUi/BaseButton.vue'
import ModalSaveToProfile from './ModalSaveToProfile.vue'
import useAuth from '@/store/store.auth'
import { useStore } from '@/store/store.pinia'
import { useProjects } from '@/store/services/projects'
import downloadProject from '@/composables/downloadProject'
import { useContent } from '@/store/services/content'
import { marked } from 'marked'

export default {
  components: { RoseChart, BaseButton, ModalSaveToProfile },
  name: 'surveyStepMainOutro',
  setup () {
    const auth = useAuth()
    const store = useStore()
    const projectStore = useProjects()
    const { doDownload } = downloadProject()
    const contentStore = useContent()
    return {
      auth,
      store,
      projectStore,
      doDownload,
      contentStore,
    }
  },
  async mounted () {
    this.getContent()
    this.project = await this.getProject(this.projectId)
    this.projectCategories = this.project.project_categories
  },
  data () {
    return {
      showSaveModal: false,
      projectCategories: [],
      project: null,
      content: null,
    }
  },
  methods: {
    async getProject () {
      return await this.projectStore.get(this.$route.params.projectId, {
        query: {
          populate: true,
        },
      })
    },
    async getContent () {
      this.content = await this.contentStore.find({
        query: {
          page: 'outro',
        },
      })
    },
    toHtml (val) {
      return marked.parse(val)
    },
  },
  computed: {
    loggedIn () {
      return this.auth.isAuthenticated
    },
    sortedProjectCats () {
      if (!this.project) {
        return []
      }
      return this.project.project_categories.concat().sort((a, b) => {
        if (a.category.position < b.category.position) {
          return -1
        }
        if (a.category.position > b.category.position) {
          return 1
        }
        return 0
      })
    },
  },
}
</script>

<style>

</style>
