<template>
  <div v-if="content" class="layout-page markdown-content">
    <div v-html="toHtml(content[0].defaultTranslation.value)"></div>
  </div>
</template>

<script>
import { useContent } from '@/store/services/content'
import { marked } from 'marked'

export default {
  name: 'Guide',
  data: () => ({
    content: null,
  }),
  setup () {
    const contentStore = useContent()
    return {
      contentStore,
    }
  },
  mounted () {
    this.getContent()
  },
  methods: {
    async getContent () {
      this.content = await this.contentStore.find({
        query: {
          page: 'guide',
        },
      })
    },
    toHtml (val) {
      return marked.parse(val)
    },
  },
}
</script>
