import { defineStore, BaseModel } from '../store.pinia'
import { api } from '../../feathers'

export class Project extends BaseModel {
  static instanceDefaults () {
    return {
      title: null,
      stateId: 1,
      userId: null,
      guestId: null,
    }
  }
}

const servicePath = 'projects'
export const useProjects = defineStore({ servicePath, Model: Project })

api.service(servicePath).hooks({})
