<template>
  <vue-final-modal
    v-bind="$attrs"
    classes="modal-container"
    :content-class="`modal-content ${dynClass}`"
    :modelValue="modelValue"
    @update:modelValue="updateModelValue"
  >
    <span class="modal__title"
      :class="size === 'full' ? 'text-black' : ''"
    >
      <slot name="title">{{ $t('Survey.New.Title') }}</slot>
    </span>
    <div class="modal__content">
      <div v-if="formState === 'loading'" class="relative h-20">
        <BaseLoader />
      </div>
      <template v-if="formState === 'normal'">
        <div class="space-y-4">
          <div class="mb-6">
            <input
              v-model="projectTitle"
              :placeholder="$t('Survey.New.Title')"
              class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <p class="mt-5">{{ $t('Survey.New.Description') }}</p>
          </div>
        </div>
      </template>
    </div>
    <div class="modal__action" v-if="formState === 'normal'">
      <slot name="actions">
        <BaseButton @click="$emit('update:modelValue', false)">{{ $t('Shared.cancel') }}</BaseButton>
        <BaseButton primary @click="submit">{{ $t('Shared.save') }}</BaseButton>
      </slot>
    </div>
  </vue-final-modal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import BaseButton from '@/components/shared/baseUi/BaseButton'
import BaseLoader from '@/components/shared/baseUi/BaseLoader.vue'
import { useStore } from '@/store/store.pinia'

export default {
  name: 'NewProjectTitleModal',
  setup () {
    const store = useStore()
    return {
      store,
    }
  },
  inheritAttrs: false,
  emits: ['update:modelValue', 'confirm'],
  components: {
    BaseButton,
    BaseLoader,
    VueFinalModal,
  },
  mounted () {
    this.store.setCurrentProjectTitle(null)
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    formState: {
      type: String, // loading || normal || saved || error
      default: 'normal',
    },
    succesMessage: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'md',
    },
    projectId: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      projectTitle: null,
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler (newVal) {},
    },
  },
  computed: {
    dynClass () {
      if (this.size === 'md') return 'bg-white min-w-90 md:min-w-1/2 lg:min-w-1/4'
      if (this.size === 'full') return 'bg-white min-w-full min-h-full'
      else return ''
    },
  },
  methods: {
    updateModelValue (val) {
      this.$emit('update:modelValue', val)
    },
    close () {
      this.$emit('update:modelValue', false)
    },
    submit () {
      this.$emit('confirm', this.projectTitle)
      this.store.setCurrentProjectTitle(this.projectTitle)
      this.$emit('update:modelValue', false)
    },
  },
}
</script>

<style scoped>
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;
  padding: 1rem;
  /* border: 1px solid #e2e8f0; */
  border-radius: 0.25rem;
  /* background: #fff; */
  max-width: 30%;
  min-width: 30%;
}
.modal__title {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 400;
}
.modal__content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem 0;
}
.modal__content > * {
  display: block;
  margin-bottom: 1rem;
}
.modal__action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem 0 0;
}
.modal__action button {
  margin-bottom: 0;
}
.modal__action > *:not(:last-child) {
  margin-right: 1rem;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.dark-mode div ::v-deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 0 0 0 1rem;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
</style>
