import { defineStore, BaseModel } from '../store.pinia'
import { api } from '../../feathers'

export class User extends BaseModel {
  static instanceDefaults () {
    return {
      name: '',
      email: '',
      roleId: 1,
      isActivated: false,
    }
  }
}

const servicePath = 'users'
export const useUsers = defineStore({ servicePath, Model: User })

api.service(servicePath).hooks({})
