import { defineStore, BaseModel } from '../store.pinia'
import { api } from '../../feathers'
import { QuestionTranslation } from './question-translations'
import { QuestionExample } from './question-examples'

export class Question extends BaseModel {
  static instanceDefaults () {
    return {
      isArchive: false,
      position: 0,
      categoryId: null,
    }
  }

  static setupInstance (data, { models }) {
    if (data.defaultTranslation) {
      data.defaultTranslation = new QuestionTranslation(data.defaultTranslation)
    }
    if (data.question_examples) {
      data.question_examples = data.question_examples.map(item => new QuestionExample(item))
    }
    return data
  }
}

const servicePath = 'questions'
export const useQuestions = defineStore({ servicePath, Model: Question })

api.service(servicePath).hooks({})
