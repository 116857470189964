
const debounce = (func, timeout) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => { func.apply(this, args) }, timeout)
  }
}

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const numberToTwoDigits = (int) => {
  if (int < 10) {
    return '0' + int
  } else return int + ''
}

const maxStringLength = (str, max) => {
  if (!str) {
    return str
  } else if (str.length > max) {
    return str.slice(0, max) + '...'
  }
  return str
}

export { sleep, debounce, numberToTwoDigits, maxStringLength }
