<template>
  <button class="rounded-xl shadow hover:shadow-xl transition-all hover:-translate-y-2 p-6 flex flex-col items-center" :class="primary ? 'bg-secondary text-white' : 'bg-white bg-opacity-80'">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'BaseActionCard',
  props: {
    primary: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style>

</style>
