<template>
  <BasePageLayout :loading="loading" class="grid grid-cols-12 gap-x-12">
    <div class="col-span-12 pb-12" v-if="project">
      <h1 class="text-3xl pb-6 space-x-2">
        <BaseButton :layout="'icon-only'" :icon="'ChevronLeft'" noBg @click="$router.push({ name: 'projects' })"/>
        <div class="inline-block">
          <span
            ref="projectTitle"
            @input="updateTitle($event.currentTarget.textContent)"
            :contenteditable="editingTitle"
            :class="{ 'bg-gray-light outline-0 p-1': editingTitle }"
          >
            {{ project.title ?? $t('Projects.Project list view.No name') }}
          </span>
        </div>
        <BaseButton v-if="editingTitle" :layout="'icon-only'" :icon="'Checkmark'" noBg @click="editTitle"/>
        <BaseButton v-else :layout="'icon-only'" :icon="'Pencil'" noBg @click="editTitle"/>
        <!-- <span @click="editTitle" class="inline-block rotate-90 cursor-pointer ml-1">✎</span> -->
      </h1>
      <ul id="controls" class="flex justify-start items-start flex-wrap -mt-2 -ml-2">
        <li><BaseButton @click="doDownload(`${$appConfig.apiBaseUrl}/data-exports`, projectId, project.title ?? $t('Projects.Project list view.No name'), 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')" class="mt-2 ml-2" :label="$t('Projects.Project view.ButtonReport')" primary /></li>
        <li><BaseButton @click="$router.push(`/survey/${projectId}`)" class="mt-2 ml-2" :label="$t('Projects.Project view.ButtonEditAnswers')" /></li>
        <li><BaseButton @click="doDownload(`${$appConfig.apiBaseUrl}/data-exports`, projectId,project.title ?? $t('Projects.Project list view.No name'), 'text/csv')" class="mt-2 ml-2" :label="$t('Projects.Project view.ButtonExportCsv')" /></li>
        <li><BaseButton @click="deleteProject" class="mt-2 ml-2" :label="$t('Projects.Project view.ButtonDeleteProject')" /></li>
      </ul>
    </div>
    <RoseChart class="col-span-12 md:col-span-4" :projectCategories="projectCategories" chartId="projectchart" />
    <div class="col-span-12 md:col-span-8">
      <h2 class="text-2xl pb-4">{{ $t('Projects.Project view.SectionTitlePrinciples') }}</h2>
      <ul class="grid grid-cols-5 gap-3">
        <li
          v-for="projectCategory in projectCategories" :key="projectCategory.id"
          class="cursor-pointer p-2 hover:bg-white hover:bg-opacity-40"
          @click="$router.push(`/survey/${projectId}/${projectCategory.category.id * 2}`)"
        >
          <img :src="`${$appConfig.apiBaseUrl}${projectCategory.category.icon}`" alt="">
          <p class="text-xs">{{ projectCategory.category.defaultTranslation.title }}</p>
        </li>
      </ul>
    </div>
  </BasePageLayout>
</template>

<script>
import BaseButton from '@/components/shared/baseUi/BaseButton.vue'
import RoseChart from '@/components/shared/RoseChart.vue'
import BasePageLayout from '@/components/shared/baseUi/BasePageLayout.vue'
import { useProjects } from '@/store/services/projects'
import { useStore } from '@/store/store.pinia'
import downloadProject from '@/composables/downloadProject'

export default {
  components: { BaseButton, RoseChart, BasePageLayout },
  name: 'ProjectView',
  setup () {
    const projectStore = useProjects()
    const { doDownload } = downloadProject()
    const store = useStore()
    store.resetCurrentProject()
    return {
      projectStore,
      store,
      doDownload,
    }
  },
  props: {
    projectId: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      projectCategories: [],
      loading: false,
      project: null,
      editingTitle: false,
    }
  },
  async mounted () {
    this.loading = true
    this.project = await this.getProject(this.projectId)
    this.projectCategories = this.project.project_categories
    this.loading = false
  },
  methods: {
    async getProject () {
      return await this.projectStore.get(this.projectId, {
        query: {
          populate: true,
        },
      })
    },
    async deleteProject () {
      if (window.confirm(this.$t('Projects.Project view.ConfirmDeleteProject'))) {
        this.project.remove().then(() => {
          this.store.resetCurrentProject()
          this.$router.push('/projects')
        })
      }
    },
    async editTitle () {
      this.editingTitle = !this.editingTitle
      await this.$nextTick()
      this.$refs.projectTitle.focus()
    },
    updateTitle (title) {
      this.project.title = title
      this.project.save()
    },
  },
}
</script>

<style>

</style>
