<template>
  <h1 class="text-3xl mb-6">
    <img :src="`${$appConfig.apiBaseUrl}${projectCategory.category.icon}`" class="h-12 inline-block">
    {{ projectCategory.category.defaultTranslation.title }}
  </h1>
  <p>{{ projectCategory.category.defaultTranslation.description }}</p>
  <div v-if="translationMedia && translationMedia.length > 0 " class="mt-6 pb-12 w-full">
    <Movie
      :key="fullUrl"
      v-if="translationMedia[0].media.type == 'video'"
      :url="fullUrl"
      :mimetype="translationMedia[0].media.mimetype"
    />
    <img
      v-else-if="translationMedia[0].media.type == 'image'"
      :src="fullUrl"
      class="border-black border-2"
    />
  </div>
</template>

<script>
import Movie from '@/components/shared/media/Movie.vue'
import { useCategoryTranslationMedia } from '@/store/services/category-translation-media'
import { config } from '@/config'

export default {
  setup () {
    const mediaStore = useCategoryTranslationMedia()
    return {
      mediaStore,
    }
  },
  name: 'SurveyStepCategoryIntro',
  props: {
    projectCategory: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    translationMedia: null,
  }),
  computed: {
    fullUrl () {
      return this.translationMedia.length > 0 ? `${config.apiBaseUrl}${this.translationMedia[0].media.location}` : null
    },
  },
  methods: {
    async getMedia () {
      this.translationMedia = await this.mediaStore.find({
        query: {
          categoryTranslationId: this.projectCategory.category.defaultTranslation.id,
        },
      })
    },
  },
  watch: {
    projectCategory () {
      this.getMedia()
    },
  },
  components: {
    Movie,
  },
  mounted () {
    this.getMedia()
  },
}
</script>

<style>

</style>
