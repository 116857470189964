<template>
  <div v-if="content" class="markdown-content">
    <!-- <h1 class="text-3xl">{{ content[0].defaultTranslation.value }}</h1>
    <div v-html="toHtml(content[1].defaultTranslation.value)"></div> -->
    <div v-html="toHtml(content[0].defaultTranslation.value)"></div>
  </div>
</template>

<script>
import { useContent } from '@/store/services/content'
import { marked } from 'marked'

export default {
  name: 'SurveyStepMainIntro',
  data: () => ({
    content: null,
  }),
  setup () {
    const contentStore = useContent()
    return {
      contentStore,
    }
  },
  mounted () {
    /*
    marked.setOptions({
      breaks: true,
    })
    */
    this.getContent()
  },
  methods: {
    async getContent () {
      this.content = await this.contentStore.find({
        query: {
          page: 'intro',
        },
      })
    },
    toHtml (val) {
      return marked.parse(val)
      // return val.replace(/(?:\r\n|\r|\n)/g, '<br>')
    },
  },
}
</script>

<style>
.markdown-content p {
  margin-bottom: 1rem;
}
.markdown-content h1 {
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
  margin-bottom: 1rem;
}
.markdown-content h2 {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
  margin-bottom: 1rem;
}
</style>
