<template>
  <div class="py-4">
    <ul class="flex flex-col" data-cy="cmsCategoriesList" v-if="!loading">
      <li
        class="grid mb-2 p-2 font-bold"
        style="grid-template-columns: 12rem 4rem;"
      >
        <h3></h3>
        <h3></h3>
      </li>
      <li v-for="(page, idx) in sections" :key="idx" class="grid items-center p-2 bg-white bg-opacity-80 mb-1" style="grid-template-columns: 5fr 1fr;">
        <div>
          <span class="uppercase ">{{ page }}</span>
        </div>
        <div class="flex justify-end">
          <BaseButton
            :label="$t('Shared.edit')"
            @click="editPage(page)"
            data-cy="cmsEditCategoryButton"
          />
        </div>
      </li>
    </ul>
    <div v-if="loading">
      {{ $t('Cms.Shared.Fetching content') }} }}}
    </div>
    <ModalEditPage v-if="showEditPageModal"
      :content="currentPage"
      :page-name="pageName"
      :model-value="showEditPageModal"
      @update:model-value="showEditPageModal = $event"
      @data-updated="getContent"
    />
  </div>
</template>

<script>
import { useContent } from '@/store/services/content'
import BaseButton from '@/components/shared/baseUi/BaseButton.vue'
import ModalEditPage from '@/components/cms/ModalEditPage.vue'

export default {
  data () {
    return {
      loading: false,
      content: [],
      currentPage: [],
      showEditPageModal: false,
    }
  },
  setup () {
    const contentStore = useContent()
    return {
      contentStore,
    }
  },
  components: {
    BaseButton,
    ModalEditPage,
  },
  computed: {
    sections () {
      const pages = this.content.flatMap(section => section.page)
      return [...new Set(pages)]
    },
  },
  async mounted () {
    this.loading = true
    await this.getContent()
    this.loading = false
  },
  methods: {
    editPage (page) {
      this.currentPage = this.content.filter(item => item.page === page)
      this.pageName = page
      this.showEditPageModal = true
    },
    async getContent () {
      this.content = await this.contentStore.find({
        query: {},
      })
    },
  },
}
</script>

<style scoped>

</style>
