<template>
  <div></div>
</template>

<script>
import useAuth from '@/store/store.auth'

export default {
  created () {
    this.logout()
  },
  methods: {
    async logout () {
      const auth = useAuth()
      await auth.logout()
      location.href = '/'
    },
  },
}
</script>

<style>
</style>
