<template>
  <BaseFormModal
    :modelValue="modelValue"
    @update:modelValue="updateModelValue"
    :modalTitle="$t('Shared.add') + ' ' + $t('Shared.question')"
    size="lg"
    :formFields="[
      { type: 'hidden', name: 'id', value: 1 },
      { type: 'textInput', name: 'title', label: 'title', value: '', placeholder: 'titel' },
    ]"
    @confirm="saveQuestion"
  />
</template>

<script>
import BaseFormModal from '@/components/shared/baseUi/BaseFormModal'
import { useStore } from '@/store/store.pinia'
import { useQuestions } from '@/store/services/questions'
import { useQuestionTranslations } from '@/store/services/question-translations'

export default {
  components: {
    BaseFormModal,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    categoryId: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:modelValue', 'data-updated'],
  data () {
    return {
      saving: false,
    }
  },
  setup () {
    const store = useStore()
    const questionStore = useQuestions()
    const questionTranslationStore = useQuestionTranslations()
    return {
      store,
      questionStore,
      questionTranslationStore,
    }
  },
  methods: {
    updateModelValue (val) {
      this.$emit('update:modelValue', val)
    },
    closeModal (close) {
      this.$emit('update:modelValue', false)
    },
    async saveQuestion (val) {
      this.saving = true
      // Create new question
      const question = await this.questionStore.create({
        categoryId: this.categoryId,
      })
      // Create translation data
      await this.questionTranslationStore.create({
        questionId: question.id,
        title: val.title,
      })
      this.$emit('data-updated')
      this.saving = false
      this.closeModal()
    },
  },
  watch: {
    modelValue (newValue) {
      if (newValue) {
        this.saving = false
      }
    },
  },
  mounted () {},
}
</script>

<style scoped>
button:not(:last-child) {
  margin-right: 1rem;
}
</style>
