<template>
  <div class="w-full relative">
    <canvas :id="chartUid" class="box-border w-full" data-cy="rosechart"></canvas>
  </div>
</template>

<script>
// to do: https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Optimizing_canvas

import { numberToTwoDigits } from '@/composables/utils'
import debounce from 'lodash/debounce'
import chart from '../../composables/createChartPie'

export default {
  props: {
    chartId: {
      type: String,
      required: true,
    },
    projectCategories: {
      type: Array,
      default: () => [],
    },
    refreshChart: {
      type: Number,
      default: 0,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    canSelectCategory: {
      type: Boolean,
      default: true,
    },
    canSaveRatings: {
      type: Boolean,
      default: true,
    },
    highlightSelectedCategory: {
      type: Boolean,
      default: true,
    },
    showLabels: {
      type: Boolean,
      default: true,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      chart: null,
      chartUidCount: 0,
      chartUid: 'chart-canvas-' + this.chartId + 0,
    }
  },
  emits: ['themeSelected'],
  methods: {
    refreshChartElement () {
      this.chartUidCount++
      this.chartUid = 'chart-canvas-' + this.chartId + this.chartUidCount
    },
    getChartData () {
      const parsedCats = []
      this.projectCategories.forEach((projectCategory, i) => {
        parsedCats.push({
          title: projectCategory.category.defaultTranslation.title,
          label: numberToTwoDigits(projectCategory.categoryId),
          color: projectCategory.category.color,
          // value: category.rating,
          value: projectCategory.score.toFixed(0),
          icon: 'kvalitetsprincip' + projectCategory.categoryId,
          // iconUrl: category.iconUrl,
          iconUrl: `${this.$appConfig.apiBaseUrl}${projectCategory.category.icon}`,
          // iconUrl: require('@/assets/icons/POS_kvalitetsprinsipp 1_pos_.png'),
          id: projectCategory.categoryId,
        })
      })
      parsedCats.sort((a, b) => {
        return a.id - b.id
      })
      return parsedCats
    },
    setChartListener () {
      this.chart.onHoverCategory((catIndex) => {
      })
      if (this.canSelectCategory) {
        this.chart.onClickCategory((catId) => {
          this.$emit('themeSelected', catId)
        })
      }
      if (this.clickable) {
        this.chart.onValueUpdate((category, value) => {
          if (this.canSaveRatings) {
            // save rating
          } else {
            // something else
          }
        })
      }
    },
    initChart (firstInit = false) {
      this.destroyChart()
      if (firstInit) this.chart = chart()
      const options = {
        canSelectCategory: this.canSelectCategory,
        canRate: this.clickable,
        showLabels: this.showLabels,
        highlightSelectedCategory: this.highlightSelectedCategory,
        xRes: 600,
        yRes: 600,
      }
      if (this.isVisible) {
        delete options.xRes
        delete options.yRes
      }
      this.chart.initChart(
        document.getElementById(this.chartUid),
        this.getChartData(),
        options,
      )
      this.setChartListener()
      window.addEventListener('resize', debounce(this.chart.resize, 250))
    },
    destroyChart () {
      if (this.chart) {
        this.chart.destroyChart()
        window.removeEventListener('resize', debounce(this.chart.resize, 250))
      }
    },
  },
  watch: {
    async refreshChart (newValue) {
      if (newValue !== 0) {
        // todo: refreshChart
      }
    },
    categories (val) {
      this.initChart()
    },
    isVisible (val) {
      if (val) {
        this.$nextTick(() => {
          this.initChart()
          // this.chart.resize(null, null)
        })
      }
    },
  },
  async mounted () {
    this.initChart(true)
    // await this.getRatings()
  },
  unmounted () {
    this.destroyChart()
  },
}
</script>

<style scoped>
</style>
