<template>
  <li class="mb-4 p-2 pr-0 grid grid-cols-12">
    <div class="flex-grow col-span-12 lg:col-span-7 xl:col-span-8">
      <div>
        {{ projectQuestion.question.defaultTranslation.title }}
      </div>
      <div v-if="projectQuestion.question.question_examples.length > 0" class="cursor-pointer mt-2 text-xs" @click="expanded = !expanded">
        <span>{{ !expanded ? shorten(firstExample.defaultTranslation.title) : '' }}</span>
      </div>
      <ul class="cursor-pointer mt-2 text-xs" @click="expanded = !expanded" v-if="expanded">
        <li v-for="(example, idx) in projectQuestion.question.question_examples" :key="idx">
          <span>{{ example.defaultTranslation.title }}</span>
          <!-- <span>{{ '<'}}</span> -->
        </li>
      </ul>
    </div>
    <BaseInputRating
      class="col-span-12 lg:col-span-5 xl:col-span-4 flex items-start pt-4 md:pt-1"
      :inputId="'input' + projectQuestion.id"
      :value="projectQuestion.answer.value"
      @answerChanged="answerChanged"
    />
  </li>
</template>

<script>
import BaseInputRating from '../shared/baseUi/BaseInputRating.vue'

export default {
  components: { BaseInputRating },
  name: 'QuestionsListItem',
  props: {
    projectQuestion: {
      type: Object,
      required: true,
    },
  },
  emits: ['updateAnswer'],
  data () {
    return {
      selected: 3,
      expanded: false,
    }
  },
  watch: {
    'question.value': {
      immediate: true,
      handler (newVal) {
        this.selected = newVal
      },
    },
    selected (newVal) {
      if (newVal !== this.projectQuestion.question.value) {
        this.$emit('updateAnswer', newVal)
      }
    },
  },
  methods: {
    shorten (text) {
      if (text.length > 120) {
        return text.slice(0, 120) + '...'
      } else {
        return text
      }
    },
    answerChanged (val) {
      this.$emit('updateAnswer', val)
    },
  },
  computed: {
    firstExample () {
      return this.projectQuestion.question.question_examples[0]
    },
  },
}
</script>

<style>

</style>
