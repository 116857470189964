<template>
  <div>
    <SiteHeader class="h-22 z-10" />
    <router-view />
    <!-- <PageBackground>
    </PageBackground> -->
    <div class="
      bg-white
      bg-primary
      bg-secondary
      bg-gray-light
      hover:bg-white
      hover:bg-primary
      hover:bg-secondary
      text-white
      hover:text-white
      text-primary
      hover:text-primary
      text-secondary
      hover:text-secondary
      text-base
      text-lg
      text-xl
      text-2xl
      h-full
      bg-white min-w-90 md:min-w-1/2 lg:min-w-1/4
    "></div>
  </div>
</template>

<script>
import SiteHeader from '@/components/shared/header/SiteHeader.vue'
// import PageBackground from './components/shared/PageBackground.vue'

export default {
  components: {
    SiteHeader,
    // PageBackground,
  },
}
</script>
<style>
body {
  background-color: rgb(237, 242, 236);
}
</style>
