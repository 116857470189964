<template>
  <BaseFormModal
    :modelValue="modelValue"
    @update:modelValue="updateModelValue"
    :modalTitle="$t('Shared.edit')"
    size="lg"
    :formFields="[
      { type: 'hidden', name: 'id', value: example.id },
      { type: 'hidden', name: 'exampleTranslationId', value: example.defaultTranslation.id },
      { type: 'textInput', name: 'title', label: 'title', value: example.defaultTranslation.title, placeholder: 'titel' },
      { type: 'number', name: 'position', label: 'position', value: example.position, placeholder: 'position' },
      { type: 'checkbox', name: $t('Cms.Shared.isArchiveQuestion'), label: $t('Cms.Shared.isArchive'), value: example.isArchive },
    ]"
    @confirm="saveExample"
  />
</template>

<script>
import BaseFormModal from '@/components/shared/baseUi/BaseFormModal'
import { useStore } from '@/store/store.pinia'
import { useQuestionExamples } from '@/store/services/question-examples'
import { useQuestionExampleTranslations } from '@/store/services/question-example-translations'
import { useQuestions } from '@/store/services/questions'
import { useFind } from 'feathers-pinia'
import { computed } from 'vue'

export default {
  components: {
    BaseFormModal,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    example: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:modelValue', 'data-updated'],
  data () {
    return {
      saving: false,
    }
  },
  setup () {
    const store = useStore()
    const exampleStore = useQuestionExamples()
    const exampleTranslationStore = useQuestionExampleTranslations()
    const questionStore = useQuestions()
    const questionParams = computed(() => {
      return {
        query: {},
      }
    })
    const questionData = useFind({ model: questionStore.Model, params: questionParams })
    const questions = questionData.items
    return {
      store,
      exampleStore,
      exampleTranslationStore,
      questionStore,
      questions,
    }
  },
  methods: {
    updateModelValue (val) {
      this.$emit('update:modelValue', val)
    },
    closeModal (close) {
      this.$emit('update:modelValue', false)
    },
    async saveExample (val) {
      this.saving = true
      // Update translation data
      await this.exampleTranslationStore.patch(val.exampleTranslationId, {
        title: val.title,
      })
      // Update example data
      await this.exampleStore.patch(val.id, {
        position: val.position,
        questionId: val.question,
        isArchive: val.isArchive,
      })
      this.$emit('data-updated')
      this.saving = false
      this.closeModal()
    },
  },
  watch: {
    modelValue (newValue) {
      if (newValue) {
        this.saving = false
      }
    },
  },
}
</script>

<style scoped>
button:not(:last-child) {
  margin-right: 1rem;
}
</style>
