<template>
  <li>
    <div
      class="grid items-center p-2 bg-white bg-opacity-80 hover:bg-gray-200 mb-1"
      style="grid-template-columns: 40px 4fr 2fr;"
    >
      <BaseIcon @click="expanded = false" :icon="'ChevronDown'" class="cursor-pointer w-8 h-8" v-if="expanded"/>
      <BaseIcon @click="expanded = true" :icon="'ChevronRight'" class="cursor-pointer w-8 h-8" v-else />
      <h3 class="">
        {{ question.defaultTranslation.title || $t('Shared.messageNoTranslation') }}
      </h3>
      <!--
      <div v-if="questionExamples.length > 0">
        <CmsQuestionExamplesRow
          v-for="(example, idx) in questionExamples" :key="idx"
          :example="example"
          @editQuestionExample="editQuestionExample(example)"
        />
      </div>
      -->
      <div class="controls flex space-x-4 justify-end">
        <BaseButton
          @click.stop="$emit('editQuestion', question)"
          :label="$t('Shared.edit') + ' ' + $t('Shared.question')"
        />
        <BaseButton
          @click.stop="$emit('addExample', question)"
          :label="$t('Shared.add') + ' ' + $t('Shared.example')"
        />
      </div>
    </div>
    <CmsQuestionExamples v-if="expanded" class="ml-6" :questionId="question.id"/>
  </li>
</template>

<script>
import BaseButton from '@/components/shared/baseUi/BaseButton'
import BaseIcon from '@/components/shared/baseUi/BaseIcon'
import CmsQuestionExamples from './CmsQuestionExamples.vue'

export default {
  name: 'CmsQuestionsRow',
  components: {
    BaseButton,
    BaseIcon,
    CmsQuestionExamples,
  },
  data: () => ({
    questionExamples: [],
    expanded: false,
  }),
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  emits: ['editQuestion', 'addExample'],
}
</script>

<style scoped>

</style>
