<template>
  <div class="layout-page">
    <h1 class="text-2xl md:text-3xl mb-6">
      {{ $t('Static pages.Home.Manchet') }}
    </h1>
    <p class="mb-6">
      {{ $t('Static pages.Home.Description') }}
    </p>
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6">
      <BaseActionCard v-if="isLoggedIn" primary>
        <h3 class="text-xl mb-2">{{ $t('Static pages.Home.Card01 title') }}</h3>
        <p class="mb-auto">{{ $t('Static pages.Home.Card01 description') }}</p>
        <BaseButton class="mt-2" primary :label="$t('Static pages.Home.Card01 button')" @click="$router.push('/projects')" />
      </BaseActionCard>
      <BaseActionCard :primary="!isLoggedIn">
        <h3 class="text-xl mb-2">{{ $t('Static pages.Home.Card02 title') }}</h3>
        <p class="mb-auto">{{ $t('Static pages.Home.Card02 description') }}</p>
        <BaseButton class="mt-2" :primary="!isLoggedIn" :label="$t('Static pages.Home.Card02 button')" @click="$router.push('/survey')" />
      </BaseActionCard>
      <BaseActionCard>
        <h3 class="text-xl mb-2">{{ $t('Static pages.Home.Card03 title') }}</h3>
        <p class="mb-auto">{{ $t('Static pages.Home.Card03 description') }}</p>
        <BaseButton class="mt-2"
          :label="$t('Static pages.Home.Card03 button')"
          @click="$router.push('/guide')"
        />
      </BaseActionCard>
      <BaseActionCard>
        <h3 class="text-xl mb-2">{{ $t('Static pages.Home.Card04 title') }}</h3>
        <p class="mb-auto">{{ $t('Static pages.Home.Card04 description') }}</p>
        <BaseButton class="mt-2"
          :label="$t('Static pages.Home.Card04 button')"
          @click="$router.push('/about/kvalitetsprinsippene')"
        />
      </BaseActionCard>
      <BaseActionCard v-if="!isLoggedIn">
        <h3 class="text-xl mb-2">{{ $t('Static pages.Home.Card05 title') }}</h3>
        <p class="mb-auto">{{ $t('Static pages.Home.Card05 description') }}</p>
        <BaseButton class="mt-2"
          v-if="!isLoggedIn"
          :label="$t('Static pages.Home.Card05 button')"
          @click="$router.push('/user/login')"
        />
      </BaseActionCard>
    </div>
    <footer class="mt-16 flex flex-col md:flex-row justify-center items-center gap-24 p-12 md:pd-16" aria-label="Logoer">
        <a href="https://byggalliansen.no/" target="_blank">
          <img width="140" src="@/assets/images/logo_byggallianse.png" />
        </a>
        <a href="https://linkarkitektur.com/" target="_blank">
          <img width="220" src="@/assets/images/logo_link.png" />
        </a>
        <a href="https://www.nodalit.com/" target="_blank">
          <img width="170" src="@/assets/images/logo_nodalit.png" />
        </a>
    </footer>
  </div>
</template>

<script>
import BaseButton from '@/components/shared/baseUi/BaseButton.vue'
import BaseActionCard from '@/components/shared/baseUi/BaseActionCard.vue'
import useAuth from '@/store/store.auth'

export default {
  components: { BaseButton, BaseActionCard },
  name: 'HomeView',
  setup () {
    const auth = useAuth()
    return {
      auth,
    }
  },
  computed: {
    isLoggedIn () {
      return this.auth.isAuthenticated
    },
  },
}
</script>
